import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import MetaDataList from './MetaDataList';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/metaData.service';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'url',
    title: t('Url'),
  },
  {
    name: 'title',
    title: t('Title'),
  },
  {
    name: 'h1',
    title: 'H1',
  },
  {
    name: 'description',
    title: t('Description'),
  },
  {
    name: 'keywords',
    title: t('Keywords'),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('core'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(MetaDataList);
