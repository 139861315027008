import React from 'react';
import SweetAlert from 'sweetalert-react';
import PropTypes from 'prop-types';

// https://github.com/chentsulin/sweetalert-react/blob/17e33f90ccb743e884549e537faeaeb413121a66/examples/complex/components/App.js

const Confirm = ({ show, cancelCallback, successCallback, t }) => (
  <div>
    <SweetAlert
      show={show}
      title={t('Delete')}
      text={t('Are you sure you want to delete this item?')}
      showCancelButton
      type="warning"
      confirmButtonText={t('OK')}
      confirmButtonColor="#f86c6b"
      cancelButtonText={t('Cancel')}
      onConfirm={() => {
        successCallback();
      }}
      onCancel={cancelCallback}
      onClose={() => cancelCallback} // eslint-disable-line no-console
    />
  </div>
);

Confirm.propTypes = {
  show: PropTypes.bool.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired,
  // title: PropTypes.string.isRequired,
  // text: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default Confirm;
