import { compose, hoistStatics, withState, withHandlers, withProps } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import UpdateSitemap from './UpdateSitemap';
import ModelService from '../../../../services/settings.service';

const onClick = props => async () => {
  props.setIsUpdating(true);
  await ModelService.updateSitemap();
  props.setIsUpdating(false);
};

const defaultIcon = 'fa fa-sitemap mr-1';
const updatingIcon = 'fa fa-refresh fa-spin mr-1';

const enhance = compose(
  withState('isUpdating', 'setIsUpdating', false),
  translate('core'),
  withProps(props => ({
    icon: props.isUpdating ? updatingIcon : defaultIcon,
  })),
  withHandlers({
    onClick,
  }),
);

export default withRouter(hoistStatics(enhance)(UpdateSitemap));
