import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { Link } from 'react-router-dom';
import mlHelper from '../../helpers/ml.helper';
import JobApplications from './JobApplications';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/jobApplications.service';
import gc from '../../components/cms/gridComponents';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'title',
    title: t('Title'),
  },
  {
    name: 'file',
    title: t('File'),
  },
  {
    name: 'names',
    title: t('Names'),
  },
  {
    name: 'email',
    title: t('Email'),
  },
  {
    name: 'phone',
    title: t('Phone'),
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  mlHelper.core,
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(JobApplications);
