const add0 = number => (number > 9 ? '' : '0');

const dateTimeSeparator = ' ';
const timeSeparator = ':';

const dateFormat = 'Y-m-d';

/**
 * Formatting date by template
 * @param date
 * @param withoutTime
 * @param defaultString
 * @returns {*}
 */
const getFormattedDate = (date, withoutTime, defaultString = '-') => {
  if (!date) return defaultString;
  const dateObject = new Date(date);
  if (!dateObject) return defaultString;
  const d = add0(dateObject.getDate()) + dateObject.getDate();
  const m = add0(dateObject.getMonth() + 1) + (dateObject.getMonth() + 1);
  const Y = dateObject.getFullYear().toString();

  let formattedDate = dateFormat;
  formattedDate = formattedDate.replace('d', d).replace('m', m).replace('Y', Y);

  if (!withoutTime) {
    formattedDate += dateTimeSeparator
      + add0(dateObject.getHours()) + dateObject.getHours().toString()
      + timeSeparator + add0(dateObject.getMinutes()) + dateObject.getMinutes();
  }
  return formattedDate;
};

export default { getFormattedDate };
