import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import logo from '../../assets/img/brand/ll3.svg';
import InputGroup from '../../components/cms/form/inputGroup';

const ConfirmInvite = ({ onChange, onSubmit, t, formData, errors, isSubmitting }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <div className="center mb-2">
                    <img src={logo} alt="" />
                  </div>
                  <p className="text-muted">{t('Please fill this form to continue registration')}</p>
                  <InputGroup
                    name="username"
                    placeholder={t('Username')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.username}
                    icon="fa fa-user"
                  />
                  <InputGroup
                    name="email"
                    placeholder={t('E-mail')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.email}
                    icon="fa fa-at"
                  />
                  <InputGroup
                    name="first_name"
                    placeholder={t('First name')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.first_name}
                    icon="fa fa-address-card-o"
                  />
                  <InputGroup
                    name="last_name"
                    placeholder={t('Last name')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.last_name}
                    icon="fa fa-address-card-o"
                  />
                  <InputGroup
                    name="company_name"
                    placeholder={t('Company name')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.company_name}
                    icon="fa fa-group"
                  />
                  <InputGroup
                    name="phone"
                    placeholder={t('Phone')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.phone}
                    icon="fa fa-phone"
                  />
                  <InputGroup
                    type="password"
                    name="password"
                    placeholder={t('Password')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.password}
                    icon="fa fa-lock"
                  />
                  <InputGroup
                    type="password"
                    name="repeat"
                    placeholder={t('Repeat password')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.repeat}
                    icon="fa fa-lock"
                  />
                  <Row>
                    <Col xs="6">
                      <Button disabled={isSubmitting} color="primary" className="px-4">{t('Confirm invite')}</Button>
                    </Col>
                    <Col xs="6" className="text-right" />
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

ConfirmInvite.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default ConfirmInvite;
