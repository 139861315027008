import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import InputGroup from '../../form/inputGroup';

const InputColumn = ({ value, onChange, onSubmit, name, item, inputProps, hasPermission, ...props }) => (
  <Form onSubmit={onSubmit}>
    <InputGroup disabled={typeof hasPermission !== 'undefined' ? hasPermission : props.disabled} style={{ minWidth: '50px' }} value={value} onChange={onChange} name={name} id={name + item.id} {...inputProps} />
  </Form>
);

InputColumn.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
};

export default InputColumn;
