import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import './index.css';
import App from './App';
import i18n from './i18n';
import configureStore from './store';

const { persistor, store } = configureStore();

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';

const Root = () => (
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
