import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/widget.service';
import PreviewImage from '../../components/previewImage';
import gc from '../../components/cms/gridComponents';

const getColumns = t => [
  {
    name: 'title',
    title: t('Title'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.title}</Link>),
  },
  {
    name: 'image',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage height="35" src={ModelService.getImage(item.image, 100, 100)} alt={item.name} />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
  },
  // {
  //   name: 'created_at',
  //   title: t('Created at'),
  //   value: gc.dateDataCell({ attr: 'created_at' }),
  //   filter: false,
  // },
  // {
  //   name: 'updated_at',
  //   title: t('Updated at'),
  //   value: gc.dateDataCell({ attr: 'updated_at' }),
  //   filter: false,
  // },
  {
    name: 'type',
    title: t('Type'),
    value: ({ item: { type } }) => (
      <Badge color="secondary">
        {ModelService.getTypeTitle(type, t)}
      </Badge>
    ),
    filter: gc.selectFilter(ModelService.getTypeOptions(t)),
  },
  {
    name: 'position',
    title: t('Position'),
    value: ({ item }) => (
      <Badge color="secondary">
        {ModelService.getPositionTitle(item.position, t)}
      </Badge>
    ),
    // filter: gc.selectFilter(ModelService.getPositionOptions(t)),
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
  },
  {
    name: 'status',
    title: t('Status'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'status' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

export default getColumns;
