import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle, withState } from 'recompose';
import { translate } from 'react-i18next';
import { userActions } from '../../reducers/user';
import { authActions } from '../../reducers/auth';
import Logout from './Logout';
import AuthService from '../../services/auth.service';
import { permissionsActions } from '../../reducers/permissions';

const mapDispatchToProps = {
  clearUser: userActions.clearUser,
  clearToken: authActions.clearToken,
  clearPermissions: permissionsActions.clearPermissions,
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', true),
  translate('core'),
  lifecycle({
    async componentDidMount() {
      await AuthService.logout();
      await AuthService.clearToken();
      await this.props.clearToken();
      await this.props.clearUser();
      await this.props.clearPermissions();
      // this.props.setIsLoading(false);
    },
  }),
);

export default hoistStatics(enhance)(Logout);
