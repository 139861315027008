import axios from 'axios';
import apiService from './api.service';
import apiRoutes from './api.routes';

const defaultPcCb = (pc) => { console.log(pc, ' %'); };

const headers = {
  'Content-Type': 'multipart/form-data',
};

const upload = async (formData, setUploadingPc = defaultPcCb) => {
  return apiService.catchError(async () => {
    const { data } = await axios.post(apiService.apiUrl + apiRoutes.upload, formData, {
      headers,
      onUploadProgress: event => setUploadingPc(Math.floor((event.loaded * 100) / event.total)),
    });
    return data;
  });
};

const destroy = async (id) => {
  return apiService.catchError(async () => {
    const { data } = await axios.delete(apiService.apiUrl
      + apiRoutes.deleteTempFile
      + apiRoutes.slash + id);
    return data;
  });
};

const getTempFilePath = filename => `${apiService.baseUrl}/uploads/temp-files/${filename}`;

export default {
  upload,
  destroy,
  getTempFilePath,
};
