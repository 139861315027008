const metaFields = {
  meta_title: '',
  meta_h1: '',
  meta_description: '',
  meta_keywords: '',
};

export default {
  metaFields,
};
