import React from 'react';
import { Trans } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import InputGroup from '../../../components/cms/form/inputGroup';

const twoCols = { xs: 12, md: 6 };

const MetaDataFields = ({ errors, onChange, formData }) => (
  <div>
    <Row>
      <Col {...twoCols}>
        <InputGroup
          name="meta_title"
          label={(<Trans>Meta title</Trans>)}
          errors={errors}
          onChange={onChange}
          formData={formData}
          icon="fa fa-search"
        />
      </Col>
      <Col {...twoCols}>
        <InputGroup
          name="meta_h1"
          label={(<Trans>Meta H1</Trans>)}
          errors={errors}
          onChange={onChange}
          formData={formData}
          icon="fa fa-search"
        />
      </Col>
    </Row>
    <Row>
      <Col {...twoCols}>
        <InputGroup
          name="meta_description"
          type="textarea"
          label={(<Trans>Meta description</Trans>)}
          errors={errors}
          onChange={onChange}
          formData={formData}
          icon="fa fa-search"
        />
      </Col>
      <Col {...twoCols}>
        <InputGroup
          name="meta_keywords"
          type="textarea"
          label={(<Trans>Meta keywords</Trans>)}
          errors={errors}
          onChange={onChange}
          formData={formData}
          icon="fa fa-search"
        />
      </Col>
    </Row>
  </div>
);

MetaDataFields.propTypes = {
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MetaDataFields;
