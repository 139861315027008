import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import User from './User';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/user.service';
import LanguageService from '../../../services/language.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../reducers/upload';
import { userActions } from '../../../reducers/user';
import RoleService from '../../../services/role.service';
import { permissionsActions } from '../../../reducers/permissions';
import canUpdate from '../../../components/cms/permissions/CanUpdate';

const imageSelector = uploadSelectors.getTempFile('image');

const mapStateToProps = state => ({
  userId: state.user.user.id,
  image: imageSelector(state),
});

const mapDispatchToProps = {
  removeTempFile: uploadActions.removeTempFile,
  setUser: userActions.setUser,
  updatePermissions: permissionsActions.updatePermissions,
};

const defaultFormData = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  company_name: '',
  phone: '',
  password: '',
  image: '',
  enable_2fa: false,
  is_super_admin: false,
  admin_language: '',
  status: ModelService.STATUS_ACTIVE,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { image, isNewModel, history, formData, t } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
  });

  if (!errors && model && props.userId === model.id) {
    props.setUser({ user: model });

    props.updatePermissions(model.permissionsList);

    if (model.admin_language && model.admin_language !== props.i18n.language) {
      props.i18n.changeLanguage(model.admin_language);
    }
  }
  if (!errors) {
    if (redirect) {
      if (props.match.url === '/profile') {
        return history.goBack();
      }
      return history.push(ModelService.baseRoute);
    }
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    props.removeTempFile('image');
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    getAvatar: ModelService.getAvatar,
  }),
  withState('languages', 'setLanguages', []),
  withState('roles', 'setRoles', []),
  ...fh.defaultRecompose(defaultFormData),
  lifecycle({
    async componentDidMount() {
      if (this.props.match.url === '/profile') {
        const model = await ModelService.get(this.props.userId);
        await applyModel(this.props.setFormData, model);
        this.props.setIsLoading(false);
      }
      const languages = await LanguageService.getAll({
        admin_status: 1,
      });
      const roles = await RoleService.getAll();

      await this.props.setLanguages(languages);
      await this.props.setRoles(roles);
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('User') + fh.getTitleEnd(props),
    statusList: ModelService.getStatusOptions(props.t),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
  }),
);

export default canUpdate(ModelService)(withRouter(hoistStatics(enhance)(WithLoading(User, CardLoading))));
