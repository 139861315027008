import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

const ColumnFilter = ({ canFilter, onFilter, value, column }) => (
  <React.Fragment>
    <td>
      { canFilter && typeof column.filter !== 'function' && (
        <Input value={value} onChange={onFilter} />
      )}
      { canFilter && typeof column.filter === 'function' && column.filter({
        value,
        onFilter,
      })}
    </td>
  </React.Fragment>
);

ColumnFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  canFilter: PropTypes.bool.isRequired,
  // value: PropTypes.oneOfType(
  //   PropTypes.string,
  //   PropTypes.number,
  // ).isRequired,
};

export default ColumnFilter;
