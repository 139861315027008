import React from 'react';
import { FormGroup, Label, InputGroup as IG, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import Confirm from '../../../confirm';
import TempFile from '../tempFile';
import uploadImage from '../../../../assets/img/upload.png';

const Upload = ({
  id,
  t,
  label,
  onChange,
  tempFiles,
  hideConfirm,
  showConfirm,
  onRemove,
  onRemoveCallback,
}) => (
  <FormGroup className="mt-1">
    <div className="center">
      <strong>
        {label && <Label htmlFor={id}>{label}</Label>}
      </strong>
    </div>
    <IG className="upload-area-wrapper">
      <input id={id} type="file" onChange={onChange} className="input-file" multiple />
      <div className="upload-area">
        <img src={uploadImage} alt="" />
      </div>
    </IG>
    {tempFiles.length > 0 && (
      <Table responsive hover striped className="attachments mt-2" >
        { tempFiles.map((tempFile, key) => tempFile && (
          <TempFile id={key} tempFile={tempFile} onRemove={onRemove} />
        ))}
      </Table>
    )}
    <Confirm
      show={showConfirm}
      cancelCallback={hideConfirm}
      successCallback={onRemoveCallback}
    />
  </FormGroup>
);

Upload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tempFiles: PropTypes.array.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  onRemoveCallback: PropTypes.func,
};

export default Upload;
