import React from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  CardBody,
  Card,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import SubmitButtons from '../../components/cms/form/submitButtons';
import InputGroup from '../../components/cms/form/inputGroup';
import TestMailjet from './components/testMailjet';
import UpdateSitemap from './components/updateSitemap';

const twoCols = { xs: 12, md: 6 };

const Settings = ({
  activeTab,
  settingsTypes,
  t,
  onSubmit,
  isSubmitting,
  isSubmittingRedirect,
  onChange,
  onChangeByGroup,
  goToType,
  errors,
  formData,
}) => (
  <div className="settings-card">
    <Card>
      <CardHeader>
        <strong><i className="fa fa-cogs" /> {t('Settings')}</strong>
      </CardHeader>
      <CardBody>
        <form onSubmit={onSubmit}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === settingsTypes.GENERAL })}
                onClick={() => { goToType(settingsTypes.GENERAL); }}
              >
                <i className="fa fa-cog" /> {t('General')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === settingsTypes.SECURITY })}
                onClick={() => { goToType(settingsTypes.SECURITY); }}
              >
                <i className="fa fa-lock" /> {t('Security')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === settingsTypes.INTEGRATIONS })}
                onClick={() => { goToType(settingsTypes.INTEGRATIONS); }}
              >
                <i className="fa fa-external-link-square" /> {t('Integrations')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={settingsTypes.GENERAL}>
              <Row>
                <Col {...twoCols}>
                  <Card>
                    <CardHeader>
                      <strong><i className="fa fa-database" /> {t('Admin GUI')}</strong>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        name="admin.email"
                        label={t('Admin e-mail')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.admin.email}
                        icon="fa fa-at"
                      />
                      <InputGroup
                        name="admin.domain"
                        label={t('Domain')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.admin.domain}
                        icon="fa fa-anchor"
                      />
                      <InputGroup
                        name="admin.ssl"
                        type="checkbox"
                        label={t('SSL')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        checked={parseInt(formData.admin.ssl, 10)}
                        value={1}
                        icon="fa fa-key"
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col {...twoCols}>
                  <Card>
                    <CardHeader>
                      <strong><i className="fa fa-certificate" /> {t('Site')}</strong>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        name="site.email"
                        label={t('Admin e-mail')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.site.email}
                        icon="fa fa-at"
                      />
                      <InputGroup
                        name="site.domain"
                        label={t('Domain')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.site.domain}
                        icon="fa fa-anchor"
                      />
                      <InputGroup
                        name="site.ssl"
                        type="checkbox"
                        label={t('SSL')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        checked={parseInt(formData.site.ssl, 10)}
                        value={1}
                        icon="fa fa-key"
                      />
                      <InputGroup
                        name="site.dir"
                        label={t('Directory')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.site.dir}
                        icon="fa fa-anchor"
                      />
                      <InputGroup
                        name="site.robots"
                        type="textarea"
                        rows="10"
                        label={t('robots.txt')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.site.robots}
                        icon="fa fa-info"
                      />
                      <UpdateSitemap />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                showSaveButton={false}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </TabPane>
            <TabPane tabId={settingsTypes.SECURITY}>
              <Row>
                <Col {...twoCols}>
                  <InputGroup
                    name="allowed_countries"
                    label={t('Allowed countries')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.allowed_countries}
                    icon="fa fa-flag"
                  />
                  <p className="text-muted small">
                    <a href="https://www.nationsonline.org/oneworld/country_code_list.htm" target="_blank" rel="noreferrer noopener">{t('ISO Alpha 2 country code')}</a>
                    <br />
                    {t('Example')}: DE,gb, fr
                  </p>
                  <InputGroup
                    name="login_attempts"
                    label={t('Login attempts')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.login_attempts}
                    icon="fa fa-user-circle"
                  />
                  <InputGroup
                    name="login_attempts_time"
                    label={t('Login attempts time (in minutes)')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.login_attempts_time}
                    icon="fa fa-clock-o"
                  />
                </Col>
                <Col {...twoCols}>
                  <InputGroup
                    name="allowed_file_types"
                    label={t('Allowed file types')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.allowed_file_types}
                    icon="fa fa-file"
                  />
                  <p className="text-muted small">{t('Example')}: jpg, jpeg,GIF, Docx</p>
                  <InputGroup
                    name="allowed_image_sizes"
                    type="textarea"
                    label={t('Allowed image sizes')}
                    rows={4}
                    onChange={onChange}
                    errors={errors}
                    value={formData.allowed_image_sizes}
                    icon="fa fa-crop"
                  />
                  <p className="text-muted small">{t('Example')}: 35_35,35_0,0_35,100_100, 150_0,0_150,150_150</p>
                </Col>
              </Row>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                showSaveButton={false}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </TabPane>
            <TabPane tabId={settingsTypes.INTEGRATIONS}>
              <Row>
                <Col {...twoCols}>
                  <Card>
                    <CardHeader>
                      {/*<strong><i className="fa fa-paper-plane" /> {t('Mailjet')}</strong>*/}
                      <strong><i className="fa fa-paper-plane" /> {t('Mailing')}</strong>
                    </CardHeader>
                    <CardBody>
                      {/*<InputGroup*/}
                        {/*name="mailjet.api_key_public"*/}
                        {/*label={t('Public API key')}*/}
                        {/*onChange={onChangeByGroup}*/}
                        {/*errors={errors}*/}
                        {/*value={formData.mailjet.api_key_public}*/}
                        {/*icon="fa fa-certificate"*/}
                      {/*/>*/}
                      {/*<InputGroup*/}
                        {/*name="mailjet.api_key_secret"*/}
                        {/*label={t('Secret API key')}*/}
                        {/*onChange={onChangeByGroup}*/}
                        {/*errors={errors}*/}
                        {/*value={formData.mailjet.api_key_secret}*/}
                        {/*icon="fa fa-lock"*/}
                      {/*/>*/}
                      {/*<hr />*/}
                      <InputGroup
                        name="mailjet.from_email"
                        label={t('From E-mail')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.mailjet.from_email}
                        icon="fa fa-at"
                      />
                      <InputGroup
                        name="mailjet.from_name"
                        label={t('From name')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.mailjet.from_name}
                        icon="fa fa-envelope-o"
                      />
                      {/*<hr />*/}
                      {/*<InputGroup*/}
                        {/*name="mailjet.email_welcome"*/}
                        {/*label={t('Welcome e-mail name')}*/}
                        {/*onChange={onChangeByGroup}*/}
                        {/*errors={errors}*/}
                        {/*value={formData.mailjet.email_welcome}*/}
                        {/*icon="fa fa-star"*/}
                      {/*/>*/}
                      {/*<InputGroup*/}
                        {/*name="mailjet.email_forgot_password"*/}
                        {/*label={t('Forgot password e-mail name')}*/}
                        {/*onChange={onChangeByGroup}*/}
                        {/*errors={errors}*/}
                        {/*value={formData.mailjet.email_forgot_password}*/}
                        {/*icon="fa fa-lock"*/}
                      {/*/>*/}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <strong><i className="fa fa-paper-plane" /> {t('ReCaptcha')}</strong>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        name="recaptcha.api_key_public"
                        label={t('Public API key')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.recaptcha.api_key_public}
                        icon="fa fa-certificate"
                      />
                      <InputGroup
                        name="recaptcha.api_key_secret"
                        label={t('Secret API key')}
                        onChange={onChangeByGroup}
                        errors={errors}
                        value={formData.recaptcha.api_key_secret}
                        icon="fa fa-lock"
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col {...twoCols}>
                  {/*<TestMailjet />*/}
                </Col>
              </Row>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                showSaveButton={false}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </TabPane>
          </TabContent>
        </form>
      </CardBody>
    </Card>
  </div>
);

export default Settings;
