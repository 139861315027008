import React from 'react';
import api from '../../services/api.service';

const defaultImage = `${api.baseUrl}/default.png`;

const PreviewImage = ({ src, showDefault, ...props }) => (
  <React.Fragment>
    {src && (
      <img src={src} alt="" {...props} />
    )}
    {!src && (typeof showDefault === 'undefined' || showDefault) && (<img src={defaultImage} alt="" {...props} />)}
    {!src && (typeof showDefault !== 'undefined' && !showDefault) && (<span />)}
  </React.Fragment>
);

export default PreviewImage;
