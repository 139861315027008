import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

const locationHelper = locationHelperBuilder({});

export const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: '/login',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => !!state.auth.token,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,

  // authenticatingSelector: state => !state.user.user && state.auth.token,
  // Render this component when the authenticatingSelector returns true
  // AuthenticatingComponent: Loading,
  // redirectAction: routerActions.replace,
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => !state.auth.token,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
});

export const isAllowed = (user, rights) =>
  rights.some(right => user.rights.includes(right));

export const hasRole = (user, roles) =>
  roles.some(role => user.roles.includes(role));
