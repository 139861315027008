import React from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import ReactQuill from 'react-quill';
import {
  Button,
  Input,
} from 'reactstrap';

const config = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'link'], // toggled buttons
    ['blockquote', 'code-block'],
    ['formula', 'image'],
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ],
};

const Editor = props => (
  <React.Fragment>
    <div>
      <Button type="reset" size="sm" color="default" onClick={props.toggleViewCode}><i className={props.setButtonIcon()} /> {props.viewCode ? props.t('View HTML') : props.t('View code')}</Button>
    </div>
    { !props.viewCode
      ? (<ReactQuill {...props} className={(props.valid || typeof props.valid === 'undefined') ? '' : 'invalid'} onChange={props.setValue} theme="snow" modules={config} placeholder="..." />)
      : (
        <div className="ql-container2">
          <Input type="textarea" name="" invalid={!props.valid} rows="3" placeholder="..." value={props.value} onChange={(event) => { props.setValue(event.target.value); }} />
        </div>)
    }
  </React.Fragment>
);

Editor.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Editor;
