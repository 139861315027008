/**
 * The list of menu items
 * @param t
 */
const getNavs = t => ({
  items: [
    {
      divider: true,
    },
    {
      title: true,
      name: '',
    },
    {
      name: t('Dashboard'),
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: t('Pages'),
      url: '/pages',
      icon: 'icon-note',
      permissions: ['read_pages'],
    },
    {
      name: t('Posts'),
      url: '/posts',
      icon: 'icon-paper-clip',
      permissions: ['read_blog'],
    },
    // {
    //   name: t('Posts categories'),
    //   url: '/posts-categories',
    //   icon: 'icon-note',
    //   permissions: ['read_blog'],
    // },
    {
      name: t('Content'),
      url: '/content',
      icon: 'icon-rocket',
      permissions: ['read_content'],
      children: [
        {
          name: t('Widgets'),
          url: '/content/widgets',
          icon: 'icon-star',
          permissions: ['read_content'],
        },
        {
          name: t('Damages'),
          url: '/content/damages',
          icon: 'icon-question',
          permissions: ['read_content'],
        },
        {
          name: t('Solutions'),
          url: '/content/solutions',
          icon: 'icon-info',
          permissions: ['read_content'],
        },
        {
          name: t('Slides'),
          url: '/content/slides',
          icon: 'icon-picture',
          permissions: ['read_content'],
        },
        {
          name: t('Vacancies'),
          url: '/content/vacancies',
          icon: 'icon-badge',
          permissions: ['read_content'],
        },
        {
          name: t('Team'),
          url: '/content/team-members',
          icon: 'icon-people',
          permissions: ['read_content'],
        },
        {
          name: t('Partners and customers'),
          url: '/content/brands',
          icon: 'icon-like',
          permissions: ['read_content'],
        },
      ],
    },
    {
      name: t('Menus'),
      url: '/menus',
      icon: 'icon-list',
      permissions: ['read_menus'],
    },
    {
      name: t('Translations'),
      url: '/translations',
      icon: 'icon-pencil',
      permissions: ['read_translations'],
    },
    {
      name: t('Meta data'),
      url: '/meta-data',
      icon: 'icon-bulb',
      permissions: ['read_content'],
    },
    {
      name: t('Users'),
      url: '/users',
      icon: 'icon-user',
      permissions: ['read_users', 'read_roles', 'read_feedback'],
      children: [
        {
          name: t('Users'),
          url: '/users/index',
          icon: 'icon-people',
          permissions: ['read_users'],
        },
        {
          name: t('Roles'),
          url: '/users/roles',
          icon: 'icon-lock',
          permissions: ['read_roles'],
        },
        {
          name: t('Feedback'),
          url: '/users/feedback',
          icon: 'icon-envelope',
          permissions: ['read_feedback'],
        },
      ],
    },
    {
      name: t('Languages'),
      url: '/languages',
      icon: 'icon-flag',
      permissions: ['read_languages'],
    },
    {
      name: t('Settings'),
      url: '/settings',
      icon: 'icon-settings',
      permissions: ['read_settings'],
    },
    {
      name: t('Applications'),
      url: '/job-applications',
      icon: 'icon-list',
      permissions: ['read_settings'],
    },
    {
      divider: true,
    },
    {
      name: t('Logout'),
      url: '/logout',
      icon: 'icon-logout',
    },
  ],
});

/**
 * This function filter the array of menu items by permissions
 * @param items
 * @param permissions
 */
const filterItems = (items, permissions) => {
  return items.filter((root) => {
    if (!root.permissions) return true;
    if (root.permissions && root.permissions.length > 0) {
      for (const permission of root.permissions) {
        if (permissions.includes(permission)) return true;
      }
      return false;
    }
    return true;
  });
};

/**
 * This function filter the menu items tree by permissions
 * @param t
 * @param permissions
 */
export default (t, permissions) => {
  const navs = getNavs(t);
  navs.items = filterItems(navs.items, permissions);
  for (let i = 0; i < navs.items.length; i += 1) {
    if (navs.items[i].children && navs.items[i].children.length > 0) {
      navs.items[i].children = filterItems(navs.items[i].children, permissions);
    }
  }
  return navs;
};

