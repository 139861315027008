import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import Languages from './Languages';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/language.service';
import PreviewImage from '../../components/previewImage';
import gc from '../../components/cms/gridComponents';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'name',
    title: t('Name'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.name}</Link>),
  },
  {
    name: 'title',
    title: t('Title'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.title}</Link>),
  },
  {
    name: 'is_default',
    title: t('Is default'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'is_default' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    name: 'image',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage height="35" src={ModelService.getImage(item.image, 35, 35)} alt={item.name} />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'admin_status',
    title: t('Admin status'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'admin_status' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    name: 'status',
    title: t('Status'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'status' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  translate('core'),
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Languages);
