import React from 'react';
import { Progress } from 'reactstrap';
import PropTypes from 'prop-types';
import fileHelper from '../../../../helpers/file.helper';

const defaultClass = 'badge badge-primary mr-1';
const errorClass = 'badge badge-danger mr-1';
const TempFile = ({
  tempFile,
  onRemove,
  hasError,
  t,
}) => (
  <React.Fragment>
    <tr className={hasError ? 'attachment-error' : ''}>
      <td>
        <span className={hasError ? errorClass : defaultClass}>
          {fileHelper.getFileExt(tempFile.original)}
        </span>
        <b>{tempFile.original}</b>
        <i className="ml-1">({fileHelper.getFileSize(tempFile.size)})</i>
        { hasError && (<div className="error-message" >{t(tempFile.message)}</div>)}
        {tempFile.uploadingPc !== 100 && (
          <div className="attachment-progress"><Progress color="info" value={tempFile.uploadingPc} /></div>
        )}
      </td>
      <td>
        <span className="menu pull-right">
          { tempFile.filename && (
            <a href={tempFile.url} download className="btn btn-primary btn-sm btn-attachment mr-1">
              <i className="fa fa-cloud-download" />
            </a>
          )}
          <button className="btn btn-danger btn-sm btn-attachment" type="button" onClick={onRemove}>
            <i className="fa fa-close" />
          </button>
        </span>
      </td>
    </tr>
  </React.Fragment>
);

TempFile.propTypes = {
  tempFile: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default TempFile;
