import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../helpers/state.helper';

const initialState = {
  criteria: {},
};

/**
 * Update grid criteria
 * @param state
 * @param action
 * @returns {{criteria: {}}}
 */
const updateCriteria = (state, action) => {
  const { payload: { id, data } } = action;
  return {
    ...state,
    criteria: {
      ...state.criteria,
      [id]: {
        ...state.criteria[id],
        ...data,
      },
    },
  };
};

/**
 * Remove grid criteria
 * @param state
 * @param action
 * @returns {{criteria: {state: (*|initialState.tempFiles|{}|tempFiles)}}}
 */
const removeCriteria = (state, action) => {
  return {
    ...state,
    criteria: {
      state: state.tempFiles,
      [action.payload]: undefined,
    },
  };
};

const reducer = handleActions(
  {
    [types.UPDATE_CRITERIA]: updateCriteria,
    [types.REMOVE_CRITERIA]: removeCriteria,
    [types.CLEAR_ALL_CRITERIA]: mergeIn(() => initialState),
  },
  initialState,
);

export default reducer;
