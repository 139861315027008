import React from 'react';
import { FormGroup, Label, InputGroup as IG, Button, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import Confirm from '../../../confirm';
import TempFile from '../tempFile';

const InputFile = ({
  id,
  t,
  label,
  onChange,
  tempFile,
  hideConfirm,
  showConfirm,
  onRemove,
  children,
  onRemoveCallback,
}) => (
  <FormGroup className="mt-1">
    {label && <Label htmlFor={id}>{label}</Label>}
    <IG className="input-file-wrapper">
      <input id={id} type="file" onChange={onChange} className="input-file" />
      <div>
        {!!children && (
          <div className="mb-1">
            {children}
          </div>
        )}
        <div>
          <Button color="primary" className="btn-brand">
            <i className="fa fa-upload mr-2" />
            {t('Upload')}
          </Button>
        </div>
      </div>
    </IG>
    { tempFile && (<Table className="attachments mt-2"><TempFile tempFile={tempFile} onRemove={onRemove} /></Table>)}
    <Confirm
      show={showConfirm}
      cancelCallback={hideConfirm}
      successCallback={onRemoveCallback}
    />
  </FormGroup>
);

InputFile.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  tempFile: PropTypes.object,
  onRemoveCallback: PropTypes.func.isRequired,
};

export default InputFile;
