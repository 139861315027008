import { compose, hoistStatics, lifecycle, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import TestMailjet from './TestMailjet';
import fh from '../../../../helpers/form.helper';
import ModelService from '../../../../services/settings.service';

const onSubmit = props => redirect => async (event) => {
  const { formData, t } = props;
  await fh.beforeSave(event, props, redirect);
  const { send } = await ModelService.sendMailjetTestEmail({
    to: formData.test_email,
    client_username: formData.test_client_username,
    confirmation_link: formData.test_confirmation_link,
    language: formData.test_language,
  });
  send ? toast.success(t('The test mail was send')) : toast.error(t('The test mail wasn\'t sent'));
  return props.setIsSubmitting(false);
};

const enhance = compose(
  ...fh.defaultRecompose({
    test_email: 'vitalii.mykytiuk@web-logic.biz',
    test_client_username: 'test_username',
    test_confirmation_link: 'http://localhost/#test_confirmation_link',
    test_language: 'en',
  }),
  translate('core'),
  lifecycle({
    async componentDidMount() {
      this.props.setIsLoading(false);
    },
  }),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeByGroup: fh.onChangeFormDataElementByGroup,
    onChangeMl: fh.onChangeFormDataMlElement,
    goToType: props => type => props.history.push(ModelService.getUrlByType(type)),
  }),
);

export default withRouter(hoistStatics(enhance)(TestMailjet));
