import { createActions } from 'redux-actions';
import types from './types';

const { setPermissions, updatePermissions, clearPermissions } = createActions(
  types.SET_PERMISSIONS,
  types.UPDATE_PERMISSIONS,
  types.CLEAR_PERMISSIONS,
);

export default { setPermissions, updatePermissions, clearPermissions };
