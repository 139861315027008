import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Widget from './Widget';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/widget.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../reducers/upload';

const imageSelector = uploadSelectors.getTempFile('image');
const filesSelector = uploadSelectors.getMulTempFiles('images');
const videoSelector = uploadSelectors.getTempFile('video');
const videoDeSelector = uploadSelectors.getTempFile('video_de');
const videoNlSelector = uploadSelectors.getTempFile('video_nl');

const defaultFormData = {
  title: '',
  content: '',
  subtitle: '',
  url: '',
  priority: 100,
  type: ModelService.TYPE_CUSTOM,
  position: '',
  status: ModelService.ACTIVE,
  ml: {},
};

const mapStateToProps = state => ({
  image: imageSelector(state),
  files: filesSelector(state),
  video: videoSelector(state),
  video_de: videoDeSelector(state),
  video_nl: videoNlSelector(state),
});

const filterFiles = tempFiles => tempFiles.filter(tempFile => tempFile && tempFile.id).map(tempFile => tempFile.id);

const mapDispatchToProps = {
  removeTempFileById: uploadActions.removeTempFile,
  removeMulTempFilesById: uploadActions.removeMulTempFiles,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, image, files, video, video_de, video_nl } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
    files: files ? filterFiles(files) : [],
    video: video && video.id ? video.id : '',
    video_de: video_de && video_de.id ? video_de.id : '',
    video_nl: video_nl && video_nl.id ? video_nl.id : '',
  });
  if (!errors) {
    if (redirect) return history.push(model.parent_id
      ? ModelService.getUpdateUrl(model.parent_id)
      : ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    props.removeMulTempFilesById('images');
    props.removeTempFileById('image');
    props.removeTempFileById('video');
    props.removeTempFileById('video_de');
    props.removeTempFileById('video_nl');
    props.setUpdateGridFiles(!props.updateGridFiles);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};


const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('updateGridFiles', 'setUpdateGridFiles', false),
  ...fh.defaultRecompose(defaultFormData),
  defaultProps({
    getImage: ModelService.getImage,
    getVideo: ModelService.getVideo,
    gridFiles: '',
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      await fh.initModelData(props, applyModel, ModelService);
      if (props.match.params && props.match.params.parent_id) {
        props.setFormData({
          ...props.formData,
          parent_id: props.match.params.parent_id,
        });
      }
    },
  }),
  withProps(props => ({
    title: props.t('Widget') + fh.getTitleEnd(props),
    imageSrc: props.image && props.image.url
      ? props.image.url : ModelService.getImage(props.formData.image, 150, 150),
    typeList: ModelService.getTypeOptions(props.t),
    positionList: ModelService.getPositionOptions(props.t),
    videoUrl: props.video && props.video.url
      ? props.video.url : props.getVideo(props.formData.video),
    videoDeUrl: props.video_de && props.video_de.url
      ? props.video_de.url : props.getVideo(props.formData.video_de),
    videoNlUrl: props.video_nl && props.video_nl.url
      ? props.video_nl.url : props.getVideo(props.formData.video_nl),
  })),
  withHandlers(() => ({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  })),
);

export default withRouter(hoistStatics(enhance)(WithLoading(Widget, CardLoading)));
