import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../helpers/state.helper';

const initialState = {
  token: '',
};

const reducer = handleActions(
  {
    [types.SET_TOKEN]: mergeIn(action => action.payload),
    [types.CLEAR_TOKEN]: mergeIn(() => initialState),
  },
  initialState,
);

export default reducer;
