import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import Grid from '../../components/cms/grid';
import AddNewButton from '../../components/cms/grid/addNewButton';
import ModelService from '../../services/metaData.service';

const initCriteria = {
  id: '',
  url: '',
  title: '',
  h1: '',
  description: '',
  keywords: '',
  model_name: 'null',
  model_id: '',
};

const permissionToDelete = ['delete_content'];
const permissionToCreate = ['create_content'];

const MetaDataList = ({ t, getColumns }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-align-justify" /> {t('Meta data')}</strong>
          </CardHeader>
          <CardBody>
            <div className="pull-right mb-1">
              <AddNewButton
                to={ModelService.getCreateUrl()}
                permissionToCreate={permissionToCreate}
              />
            </div>
            <Grid
              id="metadata"
              service={ModelService}
              columns={getColumns(t)}
              initCriteria={initCriteria}
              permissionToDelete={permissionToDelete}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

MetaDataList.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default MetaDataList;
