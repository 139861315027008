import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/menu.service';
import gc from '../../components/cms/gridComponents';
import InputColumn from '../../components/cms/gridComponents/inputColumn';

const getColumns = t => [
  {
    name: 'title',
    title: t('Title'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.title}</Link>),
  },
  {
    name: 'position',
    title: t('Position'),
    value: ({ item }) => (
      <Badge color="secondary">
        {ModelService.getPositionTitle(item.position, t)}
      </Badge>
    ),
    filter: gc.selectFilter(ModelService.getPositionOptions(t)),
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
    value: props => (<InputColumn {...props} />),
  },
  {
    name: 'status',
    title: t('Status'),
    value: props => (<InputColumn {...props} inputProps={{ type: 'checkbox' }} />),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

export default getColumns;
