import React from 'react';
import { Input, FormGroup, Label, InputGroupAddon, InputGroupText, InputGroup as IG } from 'reactstrap';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { AppSwitch } from '@coreui/react';
import Select from 'react-select';
import ValidationMessage from '../validation/ValidationMessage';
import Editor from '../../editor';

const customStyles = {
  control: styles => ({
    ...styles,
    borderColor: '#e4e7ea !important',
    boxShadow: 'none !important',
    hoverBorderColor: '#e4e7ea !important',
    hoverBoxShadow: 'none !important',
    focusBorderColor: '#e4e7ea !important',
    focusBoxShadow: '#e4e7ea !important',
    borderRadius: '0 0.25rem 0.25rem 0',
    minHeight: '35px',
  }),
  indicatorSeparator: base => ({
    ...base,
    marginTop: '4px',
    marginBottom: '4px',
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '4px',
  }),
};

const InputGroup = ({ id, label, valid, icon, t, i18n, errorMessage, tReady, options, checked, formData, isMulti, ...props }) => (
  <FormGroup>
    { props.type === 'checkbox' &&
    <div className="va-m d-ib">
      <AppSwitch className="mx-1" variant="pill" color="primary" {...props} value={props.value.toString()} id={id} checked={!!checked} />
    </div>
    }
    {label && <Label htmlFor={id}>{label}</Label>}
    {props.type === 'editor' && (
      <div>
        <Editor
          value={props.value}
          valid={valid}
          setValue={value => props.onChange({ target: { value, name: props.name } })}
        />
        <ValidationMessage message={errorMessage} valid={valid} />
      </div>
    )}
    {props.type !== 'checkbox' && props.type !== 'editor' && (
      <IG>
        {icon && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className={icon} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        { props.type === 'select' && (
          <Input {...props} id={id} invalid={!valid}>
            {typeof props.empty !== 'undefined' && (<option value="">{props.empty}</option>)}
            {options.map(option =>
              (<option key={option.value} value={option.value}>{option.label}</option>))}
          </Input>
        )}
        { props.type === 'datetime' &&
          (<Datetime
            value={props.value}
            onChange={value => props.onChange({ target: { value, name: props.name } })}
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm:ss"
          />)
        }
        { props.type === 'select2' && (
          <Select
            closeMenuOnSelect={!isMulti}
            isMulti={isMulti}
            value={props.value}
            options={options}
            onChange={value => props.onChange({ target: { value, name: props.name } })}
            styles={customStyles}
            className="select2-cms"
            placeholder={props.placeholder || ''}
          />
        )}
        { props.type !== 'select' && props.type !== 'select2' && props.type !== 'datetime' && (
          <Input {...props} id={id} invalid={!valid} />
        )}
        <ValidationMessage message={errorMessage} valid={valid} />
      </IG>
    )}
  </FormGroup>
);

InputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  icon: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object,
  isMulti: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
};

export default InputGroup;
