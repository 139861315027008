import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ValidationMessage from '../../components/cms/form/validation/ValidationMessage';

import logo from '../../assets/img/brand/ll3.svg';

const Login = ({ onChange, onSubmit, t, valid, wasValidated, isBanned, enable2fa, onSubmitCode }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                {!enable2fa && (
                  <Form onSubmit={onSubmit}>
                    <div className="center mb-2">
                      <img src={logo} alt="" />
                    </div>
                    <p className="text-muted">{t('Sign In to your account')}</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" name="username" placeholder={t('Username')} onChange={onChange} invalid={wasValidated && !valid} />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" name="password" placeholder={t('Password')} onChange={onChange} invalid={wasValidated && !valid} />
                      <ValidationMessage message={!isBanned ? t('Invalid credentials') : t('Too many unsuccessful attempts')} valid={valid} wasValidated={wasValidated} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4">{t('Login')}</Button>
                      </Col>
                      <Col xs="6" className="text-right" >
                        <Link to="/forgot-password">{t('Forgot password')}?</Link>
                      </Col>
                    </Row>
                  </Form>
                )}
                {enable2fa && (
                  <Form onSubmit={onSubmitCode}>
                    <div className="center mb-2">
                      <img src={logo} alt="" />
                    </div>
                    <p className="text-muted">{t('The auth code have been sent to your e-mail')}</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" name="code" placeholder={t('Auth code')} onChange={onChange} invalid={wasValidated && !valid} />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4">{t('Login')}</Button>
                      </Col>
                      <Col xs="6" className="text-right" >
                        <Link to="/forgot-password">{t('Forgot password')}?</Link>
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

Login.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitCode: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  wasValidated: PropTypes.bool.isRequired,
  isBanned: PropTypes.bool.isRequired,
  enable2fa: PropTypes.bool.isRequired,
};

export default Login;
