import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form, Badge } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import dh from '../../../helpers/date.helper';

const twoCols = { xs: 12, md: 6 };

const Feedback = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  statusList,
  isSubmitting,
  isSubmittingRedirect,
}) => (
  <div>
    <Card>
      <CardHeader>
        <strong><i className="fa fa-envelope-o pr-2" />{title}</strong>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit(true)}>
          <Badge color="primary">{t('Created at')}: {dh.getFormattedDate(formData.created_at)}</Badge>
          <br />
          <Badge color="success">{t('Updated at')}: {dh.getFormattedDate(formData.updated_at)}</Badge>
          <br />
          <Badge className="mb-1" color="secondary">{t('Url')}: {formData.url}</Badge>
          <InputGroup
            label={t('Status')}
            name="status"
            type="select"
            onChange={onChange}
            errors={errors}
            value={formData.status}
            options={statusList}
            empty="-"
            icon="fa fa-cog"
          />
          <InputGroup
            name="name"
            label={t('Name')}
            onChange={onChange}
            errors={errors}
            value={formData.name}
            icon="fa fa-pencil"
          />
          <Row>
            <Col {...twoCols}>
              <InputGroup
                name="company"
                label={t('Company')}
                onChange={onChange}
                errors={errors}
                value={formData.company}
                icon="fa fa-building"
              />
            </Col>
            <Col {...twoCols}>
              <InputGroup
                name="industry"
                label={t('Industry')}
                onChange={onChange}
                errors={errors}
                value={formData.industry}
                icon="fa fa-industry"
              />
            </Col>
          </Row>
          <Row>
            <Col {...twoCols}>
              <InputGroup
                name="email"
                label={t('E-mail')}
                onChange={onChange}
                errors={errors}
                value={formData.email}
                icon="fa fa-at"
              />
            </Col>
            <Col {...twoCols}>
              <InputGroup
                name="phone"
                label={t('Phone')}
                onChange={onChange}
                errors={errors}
                value={formData.phone}
                icon="fa fa-phone"
              />
            </Col>
          </Row>
          <InputGroup
            name="message"
            label={t('Message')}
            onChange={onChange}
            errors={errors}
            type="textarea"
            value={formData.message}
            icon="fa fa-envelope-open-o"
          />
          <Row>
            <Col md={12}>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </div>
);

Feedback.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default Feedback;
