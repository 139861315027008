import { compose, hoistStatics, defaultProps, withHandlers, lifecycle, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Upload from './Upload';
import fileService from './../../../../services/file.service';
import { uploadActions } from '../../../../reducers/upload';

const getSetUploadingPcFunc = (key, updateTempFile) => uploadingPc =>
  updateTempFile(key, { uploadingPc });

const uploadTempFile = async (props, key, formData) => {
  const data = await fileService.upload(
    formData,
    getSetUploadingPcFunc(key, props.updateTempFile),
  );

  await props.updateTempFile(key, {
    id: data.id,
    filename: data.filename,
    url: data.message ? '' : fileService.getTempFilePath(data.filename),
    message: data.message,
    type: data.type,
  });
};

const onChange = props => async (event) => {
  event.persist();
  const { target: { files } } = event;
  const { tempFiles: { length } } = props;
  let i = 0;
  for (const file of files) {
    const key = length + i;
    const formData = new FormData();
    formData.append('file', file);
    await props.updateTempFile(key, {
      original: file.name,
      size: file.size,
      uploadingPc: 0,
    });
    uploadTempFile(props, key, formData);
    i += 1;
  }
};

const getOnRemoveCallback = (props, key) => () => async () => {
  props.setShowConfirm(false);
  props.removeTempFile(key);
  if (props.tempFiles[key] && props.tempFiles[key].id) {
    fileService.destroy(props.tempFiles[key].id);
  }
};

const onRemove = props => key => {
  props.setOnRemoveCallback(getOnRemoveCallback(props, key));
  props.setShowConfirm(true);
};

const hideConfirm = ({ setShowConfirm }) => () => {
  setShowConfirm(false);
};

const mapStateToProps = state => ({
  mulTempFiles: state.upload.mulTempFiles,
});

const mapDispatchToProps = {
  updateMulTempFileById: uploadActions.updateMulTempFile,
  removeMulTempFileById: uploadActions.removeMulTempFile,
  removeMulTempFilesById: uploadActions.removeMulTempFiles,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withState('showConfirm', 'setShowConfirm', false),
  withState('onRemoveCallback', 'setOnRemoveCallback'),
  defaultProps({
    label: '',
  }),
  withProps(props => ({
    removeTempFiles: () => props.removeMulTempFilesById(props.id),
    removeTempFile: key => props.removeMulTempFileById({
      id: props.id,
      key,
    }),
    updateTempFile: (key, data) => props.updateMulTempFileById({
      id: props.id,
      key,
      data,
    }),
    tempFiles: props.mulTempFiles[props.id] || [],
  })),
  withHandlers({
    onChange,
    onRemove,
    hideConfirm,
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.tempFiles) this.props.removeTempFiles();
    },
    componentWillUnmount() {
      if (this.props.tempFiles) this.props.removeTempFiles();
    },
  }),
);

export default hoistStatics(enhance)(Upload);
