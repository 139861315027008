import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BPagination, PaginationItem as PI, PaginationLink as PL } from 'reactstrap';

const Pagination = ({ goToPage, show, countPages, page, pages, countButtons }) => (
  <React.Fragment> {show && (
    <BPagination>
      { page > countButtons && (
        <React.Fragment>
          <PI>
            <PL tag="button" onClick={() => { goToPage(0); }}>1</PL>
          </PI>
          <PI>
            <div className="page-dots">...</div>
          </PI>
        </React.Fragment>
      )}
      { page > 0 && (
        <PI>
          <PL tag="button" onClick={() => { goToPage(page - 1); }}>&lt;</PL>
        </PI>
      )}
      {pages.map(pageIndex => (
        <PI key={pageIndex} active={pageIndex === page} onClick={() => { goToPage(pageIndex); }}>
          <PL tag="button">{pageIndex + 1}</PL>
        </PI>
      ))}
      { page < countPages - 1 && (
        <PI>
          <PL tag="button" onClick={() => { goToPage(page + 1); }}>&gt;</PL>
        </PI>
      )}
      { page < countPages - countButtons - 1 && (
        <React.Fragment>
          <PI>
            <div className="page-dots">...</div>
          </PI>
          <PI>
            <PL tag="button" onClick={() => { goToPage(countPages - 1); }}>{countPages}</PL>
          </PI>
        </React.Fragment>
      )}
    </BPagination>
  )}
  </React.Fragment>
);

Pagination.propTypes = {
  show: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(PropTypes.number).isRequired,
  goToPage: PropTypes.func.isRequired,
  // count: PropTypes.number.isRequired,
  // pageSize: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  countPages: PropTypes.number.isRequired,
};

export default Pagination;
