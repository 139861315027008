import { compose, hoistStatics, withState, withHandlers, lifecycle, withProps } from 'recompose';
import { translate } from 'react-i18next';
import InputColumn from './InputColumn';

const enhance = compose(
  translate('core'),
  withState('value', 'setValue', ''),
  withHandlers({
    onChange: ({ setValue, service, item, name, updateData }) => async ({ target }) => {
      const value = target.type === 'checkbox' ? (target.checked ? 1 : 0) : target.value;
      await setValue(value);
      if (target.type === 'checkbox' || target.type === 'select') {
        await service.updateAttribute(item.id, name, value);
        await updateData();
      }
    },
    onSubmit: ({ service, updateData, item, name, value }) => async (event) => {
      event.preventDefault();
      event.persist();
      await service.updateAttribute(item.id, name, value);
      await updateData();
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { setValue, item, name } = this.props;
      setValue(item[name]);
    },
  }),
  withProps((props) => {
    let { inputProps } = props;
    if (!inputProps) inputProps = {};
    else if (inputProps.type === 'checkbox') {
      inputProps.checked = !!props.value;
    }
    return {
      ...props,
      inputProps,
    };
  }),
);

export default hoistStatics(enhance)(InputColumn);
