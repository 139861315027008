import apiRoutes from './api.routes';
import ModelService from './model.service';

const WidgetService = new ModelService(apiRoutes.widgets, '/content/widgets', 'content');

WidgetService.getImage = (filename, w = false, h = false, c = false) => WidgetService.getFilePathByDir('/uploads/widgets', filename, w, h, c);
WidgetService.getVideo = filename => (filename ? WidgetService.getFilePathByDir('/uploads/widgets', filename) : '');

/** Types */
WidgetService.TYPE_CUSTOM = 1;

WidgetService.getTypeList = t => ({
  [WidgetService.TYPE_CUSTOM]: t('Custom'),
});

WidgetService.getTypeOptions = t => WidgetService
  .prepareOptionsFromSimpleObject(WidgetService.getTypeList(t));

WidgetService.getTypeTitle = (status, t) => {
  const label = WidgetService.getTypeList(t)[status];
  return label || '-';
};

/** Positions */
WidgetService.POSITION_HOME = 1;

WidgetService.getPositionList = t => ({
  [WidgetService.POSITION_HOME]: t('Home page'),
});

WidgetService.getPositionOptions = t => WidgetService
  .prepareOptionsFromSimpleObject(WidgetService.getPositionList(t));

WidgetService.getPositionTitle = (status, t) => {
  const label = WidgetService.getPositionList(t)[status];
  return label || '-';
};

export default WidgetService;
