import { compose, hoistStatics, withHandlers, lifecycle, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import Translation from './Translation';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/translation.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';

const defaultFormData = {
  key: '',
  text: '',
  is_html: ModelService.IS_HTML_NO,
  namespace: ModelService.NAMESPACE_CORE,
  ml: {},
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
  });
  if (!errors) {
    if (redirect) return history.push(ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  ...fh.defaultRecompose(defaultFormData),
  lifecycle({
    async componentDidMount() {
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Translation') + fh.getTitleEnd(props),
    namespaceList: ModelService.getNamespaceOptions(props.t),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(Translation, CardLoading)));
