import api from './api.service';
import apiRoutes from './api.routes';

const login = async (username, password) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.login, {
    username,
    password,
  });
  return data;
});

const checkAuthCode = async (username, password, code) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.checkAuthCode, {
    username,
    password,
    code,
  });
  return data;
});

const forgotPassword = async email => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.forgotPassword, {
    email,
  });
  return data;
});

const confirmInvite = async model => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.confirmInvite, model);
  return data;
});

const checkCode = async (id, code) => api.catchError(async () => {
  const { data } = await api.get(apiRoutes.checkCode, {
    id,
    code,
  });
  return data;
});

const changePassword = async (id, code, password, repeat) => api.catchError(async () => {
  const { data } = await api.post(apiRoutes.changePassword, {
    id,
    code,
    password,
    repeat,
  });
  return data;
});

const getCurrentUser = async () => {
  const { data } = await api.get(apiRoutes.currentUser);
  return data;
};

const logout = async () => {
  const { data } = await api.get(apiRoutes.logout);
  return data;
};

export default {
  login,
  getCurrentUser,
  setToken: api.setToken,
  clearToken: api.clearToken,
  logout,
  forgotPassword,
  checkCode,
  changePassword,
  confirmInvite,
  checkAuthCode,
};
