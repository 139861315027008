import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../../components/cms/form/submitButtons';

const TestMailjet = ({
  t,
  onSubmit,
  isSubmitting,
  isSubmittingRedirect,
  onChange,
  formData,
}) => (
  <div>
    <Card>
      <CardHeader>
        <strong><i className="fa fa-paper-plane-o" /> {t('Test mailjet welcome email')}</strong>
      </CardHeader>
      <CardBody>
        <InputGroup
          name="test_email"
          label={t('Send to')}
          onChange={onChange}
          value={formData.test_email}
          icon="fa fa-at"
        />
        <InputGroup
          name="test_client_username"
          label={t('Client username')}
          onChange={onChange}
          value={formData.test_client_username}
          icon="fa fa-user"
        />
        <InputGroup
          name="test_confirmation_link"
          label={t('Client link')}
          onChange={onChange}
          value={formData.test_confirmation_link}
          icon="fa fa-link"
        />
        <InputGroup
          name="test_language"
          label={t('language')}
          onChange={onChange}
          value={formData.test_language}
          icon="fa fa-language"
        />
        <SubmitButtons
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          showSaveButton={false}
          applyText={t('Send test E-mail')}
          defaultApplyClassName="fa fa-paper-plane mr-2"
          isSubmittingRedirect={isSubmittingRedirect}
        />
      </CardBody>
    </Card>
  </div>
);

TestMailjet.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default TestMailjet;
