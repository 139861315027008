import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../helpers/state.helper';

const initialState = {
  tempFiles: {},
  mulTempFiles: {},
};

const updateTempFile = (state, action) => {
  const { payload: { id, data } } = action;
  return {
    ...state,
    tempFiles: {
      ...state.tempFiles,
      [id]: {
        ...state.tempFiles[id],
        ...data,
      },
    },
  };
};

const removeTempFile = (state, action) => {
  return {
    ...state,
    tempFiles: {
      ...state.tempFiles,
      [action.payload]: undefined,
    },
  };
};

const updateMulTempFile = (state, action) => {
  const { payload: { id, key, data } } = action;
  let tempFiles = state.mulTempFiles[id];
  if (!tempFiles) {
    tempFiles = [data];
  } else if (tempFiles[key]) {
    tempFiles[key] = {
      ...tempFiles[key],
      ...data,
    };
  } else {
    tempFiles.push(data);
  }

  return {
    ...state,
    mulTempFiles: {
      ...state.mulTempFiles,
      [id]: tempFiles,
    },
  };
};

const removeMulTempFile = (state, action) => {
  const { payload: { id, key } } = action;
  let tempFiles = state.mulTempFiles[id];
  if (!tempFiles) {
    tempFiles = [];
  }
  if (tempFiles[key]) tempFiles[key] = null;
  return {
    ...state,
    mulTempFiles: {
      ...state.mulTempFiles,
      [id]: tempFiles,
    },
  };
};

const removeMulTempFiles = (state, action) => {
  return {
    ...state,
    mulTempFiles: {
      state: state.mulTempFiles,
      [action.payload]: undefined,
    },
  };
};

const reducer = handleActions(
  {
    [types.UPDATE_TEMP_FILE]: updateTempFile,
    [types.REMOVE_TEMP_FILE]: removeTempFile,
    [types.CLEAR_ALL_TEMP_FILES]: mergeIn(() => initialState),
    [types.UPDATE_MUL_TEMP_FILE]: updateMulTempFile,
    [types.REMOVE_MUL_TEMP_FILE]: removeMulTempFile,
    [types.REMOVE_MUL_TEMP_FILES]: removeMulTempFiles,
  },
  initialState,
);

export default reducer;
