import { handleActions } from 'redux-actions';
import types from './types';
import { mergeIn } from '../../helpers/state.helper';

const initialState = {
  langs: [],
  defaultLanguage: 'en',
  requestError: '',
};

const reducer = handleActions(
  {
    [types.UPDATE_SETTINGS]: mergeIn(action => action.payload),
  },
  initialState,
);

export default reducer;
