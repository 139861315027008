import api from './api.service';
import apiRoutes from './api.routes';

const get = async () => {
  return api.catchError(async () => {
    const { data } = await api.get(apiRoutes.settings);
    return data;
  });
};

const updateSitemap = async () => {
  return api.catchError(async () => {
    const { data } = await api.get(apiRoutes.updateSitemap);
    return data;
  });
};

const update = async (settings) => {
  return api.catchError(async () => {
    const { data } = await api.put(apiRoutes.settings, settings);
    return data;
  });
};

const sendMailjetTestEmail = async (params) => {
  return api.catchError(async () => {
    const { data } = await api.get(apiRoutes.testMailjet, params);
    return data;
  });
};

const getUrlByType = type => apiRoutes.settings + apiRoutes.slash + type;

export default {
  get,
  update,
  getUrlByType,
  sendMailjetTestEmail,
  updateSitemap,
};
