export default {
  login: '/auth/login',
  checkAuthCode: '/auth/check-auth-code',
  forgotPassword: '/auth/forgot-password',
  changePassword: '/auth/change-password',
  confirmInvite: '/auth/confirm-invite',
  checkCode: '/users/check-code',
  currentUser: '/auth/current',
  logout: '/auth/logout',
  roles: '/roles',
  permissions: '/permissions',
  users: '/users',
  createInvite: '/users/create-invite',
  languages: '/languages',
  menus: '/menus',
  widgets: '/widgets',
  widgetsFiles: '/widgets-files',
  jobApplications: '/job-applications',
  jobApplicationsFiles: '/job-application-files',
  jobApplicationsDeleteFile: '/job-application/file/delete',
  jobApplicationsUpload: '/job-application/file/upload',
  translations: '/translations',
  brands: '/brands',
  slides: '/slides',
  pages: '/pages',
  feedback: '/feedback',
  posts: '/posts',
  solutions: '/solutions',
  damages: '/damages',
  postsCategories: '/posts-categories',
  vacancies: '/vacancies',
  teamMembers: '/team-members',
  upload: '/temp-files/upload',
  metaData: '/meta-data',
  deleteTempFile: '/temp-files/delete',
  settings: '/settings',
  updateSitemap: '/settings/sitemap',
  slash: '/',
  testMailjet: '/test-mailjet',
};
