import React from 'react';
// import PropTypes from 'prop-types';

const Footer = () => (
  <React.Fragment>
    <span><a href="/" target="_blank" rel="noopener noreferrer"> SPEARHEAD CMS</a> &copy; 2018</span>
    <span className="ml-auto">Powered by <a href="https://econdri.com" target="_blank" rel="noopener noreferrer">econdri.com</a></span>
  </React.Fragment>
);

Footer.propTypes = {
};

export default Footer;
