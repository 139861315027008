import { compose, hoistStatics, withProps, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import ColumnTitle from './ColumnTitle';
import columnTypes from '../columnTypes';
import gh from '../grid.helper';

const getTitle = (column) => {
  let title = '';
  if (!(column.type && column.type === columnTypes.BUTTONS)) {
    title = gh.getColumnTitle(column);
  }
  return title;
};

const getCanSort = (props) => {
  const { sort, type } = props.column;
  if (type && type === columnTypes.BUTTONS) return false;
  if (typeof sort === 'undefined') return true;
  return !!sort;
};

const defaultIconClass = 'ml-1 fa fa-sort';
const iconSeparator = '-';
const getIconClass = (props) => {
  if (props.criteria.sortBy === gh.getColumnName(props.column)) {
    return defaultIconClass + iconSeparator + props.criteria.order;
  }
  return '';
};

const getOrder = (props) => {
  if (props.criteria.sortBy === gh.getColumnName(props.column)) {
    return props.criteria.order === 'asc' ? 'desc' : 'asc';
  }
  return 'asc';
};

const enhance = compose(
  translate('core'),
  withProps(props => ({
    title: getTitle(props.column),
    canSort: getCanSort(props),
    icon: getIconClass(props),
    order: getOrder(props),
    sortBy: gh.getColumnName(props.column),
  })),
  withHandlers({
    onSort: props => (event) => {
      event.preventDefault();
      props.sort({
        order: props.order,
        sortBy: props.sortBy,
      });
    },
  }),
);

export default hoistStatics(enhance)(ColumnTitle);
