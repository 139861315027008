import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const InputGroup = ({
  t,
  showApplyButton,
  isSubmitting,
  onSubmit,
  saveButtonClassName,
  applyButtonClassName,
  showSaveButton,
  saveText,
  applyText,
}) => (
  <React.Fragment>
    {showSaveButton && (
      <Button disabled={isSubmitting} type="submit" color="primary" className="mr-2 mb-1 btn-brand" onClick={onSubmit(true)}>
        <i className={saveButtonClassName} />
        {saveText || t('Save')}
      </Button>
    )}
    {showApplyButton && (
      <Button disabled={isSubmitting} type="submit" color="success" className="mr-2 mb-1 btn-brand" onClick={onSubmit(false)}>
        <i className={applyButtonClassName} />
        {applyText || t('Apply')}
      </Button>
    )}
  </React.Fragment>
);

InputGroup.propTypes = {
  t: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  saveButtonClassName: PropTypes.string.isRequired,
  applyButtonClassName: PropTypes.string.isRequired,
  showSaveButton: PropTypes.bool.isRequired,
  showApplyButton: PropTypes.bool.isRequired,
};

export default InputGroup;
