import React from 'react';
import { HOC as Permissions } from 'react-redux-permissions';

const HasPermissions = permissions => Component =>
  Permissions(permissions)(
    props => (<Component {...props} hasPermission />),
    props => (<Component {...props} hasPermission={false} />),
  );

export default HasPermissions;
