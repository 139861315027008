import api from './api.service';
import apiRoutes from './api.routes';

const slash = '/';
const updateAttributeRoute = '/updateAttribute';
const allRoute = '/all';

class ModelService {
  constructor(baseUrl, baseRoute, permSuffix = '') {
    this.baseUrl = baseUrl;
    this.baseRoute = baseRoute;
    this.permSuffix = permSuffix;

    this.ACTIVE = 1;
    this.INACTIVE = 0;

    /** Models */
    this.MODEL_PAGE = 'Page';
    this.MODEL_POST = 'Post';

    this.getModelList = t => ({
      [this.MODEL_PAGE]: t('Page'),
      [this.MODEL_POST]: t('Post'),
    });

    this.getModelOptions = t => this
      .prepareOptionsFromSimpleObject(this.getModelList(t));

    this.getModelTitle = (status, t) => {
      const label = this.getModelList(t)[status];
      return label || '-';
    };
  }
  async get(id) {
    return api.catchError(async () => {
      const { data } = await api.get(this.baseUrl + slash + id);
      return data;
    });
  }
  async getData(params) {
    return api.catchError(async () => {
      const { data, headers: { 'x-total-count': count } } = await api.get(this.baseUrl, params);
      return { data, count: parseInt(count, 10) };
    });
  }
  async getAll(params) {
    return api.catchError(async () => {
      const { data } = await api.get(this.baseUrl + allRoute, params);
      return data;
    });
  }
  async update(model) {
    return api.catchError(async () => {
      const url = model.id ? this.baseUrl + slash + model.id : this.baseUrl;
      const { data } = await api[model.id ? 'put' : 'post'](url, model);
      return data;
    });
  }
  async updateAttribute(id, attribute, value) {
    return api.catchError(async () => {
      const { data } = await api.put(
        this.baseUrl + updateAttributeRoute + slash + id,
        { attribute, value },
      );
      return data;
    });
  }
  async destroy(id) {
    return api.catchError(async () => {
      const { model } = await api.destroy(this.baseUrl + slash + id);
      return model;
    });
  }
  getFilePathByDir(dir, filename, w = false, h = false, c = false) {
    if (!filename) return '';
    let prefix = '';
    const ext = filename.split('.').pop().toLowerCase();
    const images = ['jpg', 'jpeg', 'png', 'bmp'];
    if ((w || h) && images.includes(ext)) {
      prefix = `${w}_${h}_${(c ? 'c' : 'o')}_`;
    }
    return `${api.baseUrl}${dir}/${prefix}${filename}`;
  }
  getUpdateUrl(id) {
    return `${this.baseRoute}/${id}`;
  }

  getCreateUrl() {
    return `${this.baseRoute}/create`;
  }

  getItemCreateUrl(parentId) {
    return `${this.getCreateUrl()}/${parentId}`;
  }

  prepareOptionsFromSimpleObject(object) {
    return Object.keys(object)
      .reduce((result, key) => ([...result, {
        value: key,
        label: object[key],
      }]), []);
  }

  /** CheckBoxes data methods  */

  getCheckBoxLabelList(t) {
    return {
      [this.ACTIVE]: t('Active'),
      [this.INACTIVE]: t('Inactive'),
    };
  }
  getCheckBoxOptions(t) {
    return this.prepareOptionsFromSimpleObject(this.getCheckBoxLabelList(t));
  }

  getCheckBoxLabel(state, t) {
    const value = state ? 1 : 0;
    const list = this.getCheckBoxLabelList(t);
    return list[value];
  }

  getCheckBoxColor(state) {
    return state ? 'success' : 'danger';
  }
  async getModelItemOptions(modelName, params = {}) {
    let baseUrl = '';
    switch (modelName) {
      case this.MODEL_PAGE:
        baseUrl = apiRoutes.pages;
        break;
      case this.MODEL_POST:
        baseUrl = apiRoutes.posts;
        break;
      default:
        break;
    }
    if (baseUrl) {
      const modelService = new ModelService(baseUrl, '/');
      return modelService.getAll(params);
    }
    return [];
  }

  /** Permissions */

  getDeletePermissions() {
    return this.permSuffix ? [`delete_${this.permSuffix}`] : [];
  }

  getCreatePermissions() {
    return this.permSuffix ? [`create_${this.permSuffix}`] : [];
  }

  getUpdatePermissions() {
    return this.permSuffix ? [`update_${this.permSuffix}`] : [];
  }
}

export default ModelService;
