import React from 'react';
import PropTypes from 'prop-types';
import Permissions from 'react-redux-permissions';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import MlFields from '../../../components/cms/form/mlFields';
import ModelService from '../../../services/menu.service';
import getDefaultColumns from '../getColumns';
import Grid from '../../../components/cms/grid';
import AddNewButton from '../../../components/cms/grid/addNewButton';

const getColumns = (t) => {
  const defaultColumns = getDefaultColumns(t);
  return [
    ...defaultColumns.slice(0, 1),
    ...defaultColumns.slice(2),
  ];
};

const twoCols = { xs: 12, md: 6 };

const initCriteria = {
  id: '',
  title: '',
  status: '',
  position: '',
  priority: '',
  sortBy: 'priority',
  order: 'asc',
};

const Menu = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  onChangeMl,
  typeList,
  onChangeModelName,
  modelList,
  modelItemList,
  hasPermission,
  positionList,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-list pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Row>
              <Col {...twoCols}>
                <Form onSubmit={onSubmit(true)}>
                  <fieldset disabled={!hasPermission}>
                    <InputGroup
                      name="status"
                      label={t('Status')}
                      onChange={onChange}
                      errors={errors}
                      type="checkbox"
                      value={1}
                      checked={formData.status}
                    />
                    {!formData.parent_id && (
                      <div>
                        <InputGroup
                          label={t('Position')}
                          name="position"
                          type="select"
                          onChange={onChange}
                          errors={errors}
                          value={formData.position}
                          options={positionList}
                          icon="fa fa-eye"
                          empty="-"
                        />
                      </div>
                    )}
                    <MlFields
                      onChange={onChangeMl}
                      formData={formData}
                      fields={props => (
                        <div>
                          <InputGroup
                            name="title"
                            label={t('Title')}
                            errors={errors}
                            onChange={props.onChange}
                            formData={props.formData}
                            icon="fa fa-pencil-square-o"
                          />
                        </div>
                      )}
                    />
                    {formData.parent_id && (
                      <div>
                        <InputGroup
                          label={t('Type')}
                          name="type"
                          type="select"
                          onChange={onChange}
                          errors={errors}
                          value={formData.type}
                          options={typeList}
                          icon="fa fa-cog"
                        />
                        {parseInt(formData.type, 10) === ModelService.TYPE_URL && (
                          <InputGroup
                            name="url"
                            label={t('Url')}
                            onChange={onChange}
                            errors={errors}
                            value={formData.url}
                            icon="fa fa-link"
                          />
                        )}
                        {parseInt(formData.type, 10) === ModelService.TYPE_MODEL && (
                          <Row>
                            <Col {...twoCols}>
                              <InputGroup
                                name="model_name"
                                label={t('Model name')}
                                onChange={onChangeModelName}
                                errors={errors}
                                value={formData.model_name}
                                type="select"
                                options={modelList}
                                empty="-"
                                icon="fa fa-database"
                              />
                            </Col>
                            <Col {...twoCols}>
                              <InputGroup
                                name="model_id"
                                type="select2"
                                options={modelItemList}
                                label={t('Model ID')}
                                onChange={onChange}
                                errors={errors}
                                value={formData.model_id}
                                isMulti={false}
                                icon="fa fa-hashtag"
                              />
                            </Col>
                          </Row>
                        )}
                        <InputGroup
                          name="priority"
                          label={t('Priority')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.priority}
                          icon="fa fa-sort-amount-asc"
                        />
                      </div>
                    )}
                    <Row>
                      <Col md={12}>
                        <SubmitButtons
                          isSubmitting={isSubmitting}
                          onSubmit={onSubmit}
                          isSubmittingRedirect={isSubmittingRedirect}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Form>
              </Col>
              <Col {...twoCols}>
                {formData.id && (
                  <Card className="">
                    <CardHeader>
                      {t('Menu items')}
                    </CardHeader>
                    <CardBody>
                      <Permissions allowed={['update_menus']}>
                        <div className="mb-2">
                          <AddNewButton to={ModelService.getItemCreateUrl(formData.id)} icon="fa fa-plus" text={t('Add item')} />
                        </div>
                      </Permissions>
                      <Grid
                        id="menuitems"
                        service={ModelService}
                        columns={getColumns(t)}
                        initCriteria={{ ...initCriteria, parent_id: formData.id }}
                        saveFilter={false}
                      />
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeModelName: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  typeList: PropTypes.array.isRequired,
  modelList: PropTypes.array.isRequired,
  positionList: PropTypes.array.isRequired,
  modelItemList: PropTypes.array.isRequired,
};

export default Menu;
