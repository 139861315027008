import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { HOC as Permissions } from 'react-redux-permissions';

const ButtonComponentWrap = props => (
  <div roles={props.roles} className="d-inline">
    <Button onClick={props.onClick} className={props.className}>
      <i className="fa fa-remove" />
    </Button>
  </div>);

const buttonWrap = (permissionToDelete, onClickDelete, item) => {
  const DeleteButton = Permissions(permissionToDelete, [])(
    ButtonComponentWrap,
    <Button className="btn btn-danger btn-sm mr-1 icon" disabled>
      <i className="fa fa-remove" />
    </Button>,
  );
  return (
    <DeleteButton
      onClick={() => { onClickDelete(item.id); }}
      className="btn btn-danger btn-sm mr-1 icon"
    >
      <i className="fa fa-remove" />
    </DeleteButton>);
};

const Buttons = ({
  item,
  baseRoute,
  getUpdateUrl,
  showViewButton,
  showUpdateButton,
  showDeleteButton,
  viewButtonUrl,
  column,
  onClickDelete,
  permissionToDelete,
}) => (
  <React.Fragment>
    <div className="pull-right">
      {column.customButtons && column.customButtons({ item })}
      { showViewButton && (
        <Link className="btn btn-success btn-sm mr-1 icon" to={viewButtonUrl}>
          <i className="fa fa-eye" />
        </Link>
      )}
      { showUpdateButton && (
        <Link className="btn btn-primary btn-sm mr-1 icon" to={getUpdateUrl(baseRoute, item.id)}>
          <i className="fa fa-pencil" />
        </Link>
        )}
      { showDeleteButton && (
        buttonWrap(permissionToDelete, onClickDelete, item)
      )}
    </div>
  </React.Fragment>
);

Buttons.propTypes = {
  getUpdateUrl: PropTypes.func.isRequired,
  baseRoute: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  showViewButton: PropTypes.bool.isRequired,
  showUpdateButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  viewButtonUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  permissionToDelete: PropTypes.array,
};

export default Buttons;
