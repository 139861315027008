import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import Grid from '../../components/cms/grid';
import AddNewButton from '../../components/cms/grid/addNewButton';
import ModelService from '../../services/widget.service';
import getColumns from './getColumns';

const initCriteria = {
  id: '',
  title: '',
  content: '',
  status: '',
  position: '',
  priority: '',
  type: '',
  parent_id: 'null',
  sortBy: 'priority',
  order: 'asc',
};

const Widgets = ({ t }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-star" /> {t('Widgets')}</strong>
          </CardHeader>
          <CardBody>
            <div className="pull-right mb-1">
              <AddNewButton
                to={ModelService.getCreateUrl()}
                permissionToCreate={ModelService.getCreatePermissions()}
              />
            </div>
            <Grid
              id="widgets"
              service={ModelService}
              columns={getColumns(t)}
              initCriteria={initCriteria}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Widgets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Widgets;
