import { compose, hoistStatics, defaultProps, withProps } from 'recompose';
import { translate } from 'react-i18next';
import InputGroup from './InputGroup';
import getErrorMessage from '../validation/errorMessages';

const enhance = compose(
  translate('core'),
  withProps((props) => {
    let { value, errors } = props;
    errors = errors || {};
    const { formData, id, t, name, onChange } = props;
    if (typeof value === 'undefined') {
      value = formData ? formData[name] : undefined;
    }
    value = value === null ? '' : value;
    return {
      name: name.split('.').pop(),
      valid: !errors[name],
      errorMessage: errors[name] ? getErrorMessage(
        t,
        errors[name].validation,
        errors[name].message,
      ) : '',
      value,
      id: id || name,
      onChange: name.split('.').pop() !== name ? onChange(name.split('.')[0]) : onChange,
    };
  }),
  defaultProps({
    errors: {},
    icon: '',
    isMulti: true,
    label: '',
    value: '',
    options: [],
    type: 'text',
  }),
);

export default hoistStatics(enhance)(InputGroup);
