import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Permissions from 'react-redux-permissions';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import getNavigation from '../../_nav';
// routes config
import getRoutes from '../../routes';
import Footer from '../footer';
import Header from '../header';

const Layout = ({ permissions, isLoading, t, ...props }) => (
  <div className="app">
    { !isLoading && (
      <React.Fragment>
        <AppHeader fixed>
          <Header />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={getNavigation(t, permissions)} {...props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={[
              ...getRoutes(t)]
            }
            />
            <Container fluid>
              <Switch>
                {getRoutes(t).map((route, idx) => (route.component ? (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props2 => (
                      <Permissions
                        allowed={route.permissions}
                        fallbackElement={<Redirect from="*" to="/dashboard" />}
                      >
                        <route.component {...props2} />
                      </Permissions>
                    )}
                  />
                  )
                  : (null)))
                }
                <Redirect from="/users" to="/users/index" />
                <Redirect from="*" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <Footer />
        </AppFooter>
      </React.Fragment>
    )}
  </div>
);

Layout.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Layout;
