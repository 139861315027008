import React from 'react';
import PropTypes from 'prop-types';
import { ButtonDropdown, DropdownToggle, DropdownItem as DI, DropdownMenu } from 'reactstrap';

const SelectLimit = ({ open, toggle, limit, t, changePageSize, limits }) => (
  <ButtonDropdown className="mr-1 mt-1" isOpen={open} toggle={toggle}>
    <DropdownToggle caret color="default" className="btn-ssm">
      <i className="fa fa-gear mr-1" /> {t('Page size')}: {limit}
    </DropdownToggle>
    <DropdownMenu className="btn-group-sm">
      {limits.map(number => (
        <DI key={number} active={limit === number} className="btn-sm" color="default" onClick={() => changePageSize(number)}>{number}</DI>
      ))}
    </DropdownMenu>
  </ButtonDropdown>
);

SelectLimit.propTypes = {
  changePageSize: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  limits: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SelectLimit;
