import apiRoutes from './api.routes';
import ModelService from './model.service';

const BrandService = new ModelService(apiRoutes.brands, '/content/brands', 'content');

BrandService.getImage = (filename, w = false, h = false, c = false) => BrandService.getFilePathByDir('/uploads/brands', filename, w, h, c);

/** Types */
BrandService.TYPE_PARTNER = 1;
BrandService.TYPE_CUSTOMER = 2;
BrandService.TYPE_MAIN_SLIDER = 3;

BrandService.getTypeList = t => ({
  [BrandService.TYPE_PARTNER]: t('Partner'),
  [BrandService.TYPE_CUSTOMER]: t('Customer'),
  [BrandService.TYPE_MAIN_SLIDER]: t('Main slider'),
});

BrandService.getTypeOptions = t => BrandService
  .prepareOptionsFromSimpleObject(BrandService.getTypeList(t));

BrandService.getTypeTitle = (status, t) => {
  const label = BrandService.getTypeList(t)[status];
  return label || '-';
};

export default BrandService;
