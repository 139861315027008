import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import MlFields from '../../../components/cms/form/mlFields';

// const twoCols = { xs: 12, md: 6 };

const MetaData = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  onChangeMl,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-language pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit(true)}>
              <InputGroup
                name="url"
                label={t('Url')}
                onChange={onChange}
                errors={errors}
                value={formData.url}
                icon="fa fa-link"
              />
              <MlFields
                onChange={onChangeMl}
                formData={formData}
                fields={(props) => (
                  <div>
                    <InputGroup
                      name="title"
                      label={t('Title')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-file-text-o"
                    />
                    <InputGroup
                      name="h1"
                      label="H1"
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-file-text-o"
                    />
                    <InputGroup
                      name="description"
                      label={t('Description')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-file-text-o"
                    />
                    <InputGroup
                      name="keywords"
                      label={t('Keywords')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-file-text-o"
                    />
                    <InputGroup
                      name="prefix"
                      label={t('Url prefix')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-file-text-o"
                    />
                  </div>
                )}
              />
              <Row>
                <Col md={12}>
                  <SubmitButtons
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    isSubmittingRedirect={isSubmittingRedirect}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

MetaData.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onChangeMl: PropTypes.func.isRequired,
};

export default MetaData;
