import React from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import logo from '../../assets/img/brand/ll3.svg';
import InputGroup from '../../components/cms/form/inputGroup';

const ForgotPassword = ({ onChange, onSubmit, t, email, errors, isSubmitting }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col md="5">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <div className="center mb-2">
                    <img src={logo} alt="" />
                  </div>
                  <p className="text-muted">{t('Enter your e-mail for password recovering')}</p>
                  <InputGroup
                    id="email"
                    name="email"
                    placeholder={t('E-mail')}
                    onChange={onChange}
                    errors={errors}
                    value={email}
                    icon="fa fa-at"
                  />
                  <Row>
                    <Col xs="6">
                      <Button disabled={isSubmitting} color="primary" className="px-4">{t('Recover password')}</Button>
                    </Col>
                    <Col xs="6" className="text-right" >
                      <Link to="/login">{t('Login')}</Link>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  </div>
);

ForgotPassword.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default ForgotPassword;
