import { compose, hoistStatics, lifecycle, withState, withHandlers, withProps, defaultProps, withPropsOnChange } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import Grid from './Grid';
import gh from './grid.helper';
import { gridActions } from '../../../reducers/grid';
import WithLoading from '../../WithLoading';

const updateData = async (props, criteria) => {
  const { data, count } = await props.service.getData(criteria || props.criteria);
  await props.setCount(count);
  await props.setData(data);
};

const goToPage = props => async (nextPage) => {
  const page = nextPage > -1 ? nextPage : 0;
  const criteria = {
    ...props.criteria,
    page,
  };
  await updateData(props, criteria);
  await props.setCriteria(criteria);
};

const changePageSize = props => async (limit) => {
  const criteria = {
    ...props.criteria,
    limit,
    page: 0,
  };
  props.setCriteria(criteria);
  await updateData(props, criteria);
};

const filter = props => async ({ attribute, value }) => {
  if (props.timer) {
    clearTimeout(props.timer);
    props.setTimer(false);
  }
  const criteria = {
    ...props.criteria,
    [attribute]: value,
    page: 0,
  };
  props.setCriteria(criteria);
  props.setTimer(setTimeout(() => {
    updateData(props, criteria);
  }, props.timeout));
};

const sort = props => async ({ order, sortBy }) => {
  if (props.timer) {
    clearTimeout(props.timer);
    props.setTimer(false);
  }
  const criteria = {
    ...props.criteria,
    order,
    sortBy,
  };
  props.setCriteria(criteria);
  await updateData(props, criteria);
};

const destroyItemCallback = (props, id) => () => async () => {
  props.setShowConfirm(false);
  await props.service.destroy(id);
  const { count, criteria: { limit, page } } = props;
  const countPages = gh.getPageCount(count, limit);
  if (page === countPages - 1 && countPages !== gh.getPageCount(count - 1, limit)) {
    goToPage(props)(props.criteria.page - 1);
  } else {
    updateData(props, false);
  }
};

const destroyItem = props => async (id) => {
  await props.setConfirmCallback(destroyItemCallback(props, id));
  await props.setShowConfirm(true);
};

const hideConfirm = ({ setShowConfirm }) => () => {
  setShowConfirm(false);
};

const defaultCriteria = {
  limit: 10,
  page: 0,
  order: 'asc',
  sortBy: 'id',
};

const resetFilter = props => async () => {
  await props.removeCriteria();
  let criteria = defaultCriteria;
  if (props.initCriteria) {
    criteria = {
      ...defaultCriteria,
      ...props.initCriteria,
    };
  }
  await props.setCriteria(criteria);
  updateData(props, criteria);
};

const compare2Criteria = (criteria1, criteria2) => {
  if (!criteria2) return true;
  for (const param of Object.keys(criteria2)) {
    if (param !== 'page') {
      if (criteria1[param] !== criteria2[param]) {
        if (
          !((criteria1[param] === '' || typeof criteria1[param] === 'undefined')
            && (criteria2[param] === '' || typeof criteria2[param] === 'undefined'))
          && !(typeof criteria2[param] === 'object' && criteria2[param].length === 0)
          && !(typeof criteria2[param] === 'object' && criteria2[param].value === '' && criteria2[param].label === '-')
        ) return false;
      }
    }
  }
  return true;
};

const defaultConfirmCallback = () => () => { console.log('confirm'); };

const mapStateToProps = state => ({
  allCriteria: state.grid.criteria,
});

const mapDispatchToProps = {
  updateCriteriaById: gridActions.updateCriteria,
  removeCriteriaById: gridActions.removeCriteria,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  defaultProps({
    timeout: 200,
    updateDataFlag: false,
    showFilter: true,
    showHeader: true,
  }),
  withState('timer', 'setTimer', false),
  withState('data', 'setData', []),
  withState('count', 'setCount', 0),
  withState('showConfirm', 'setShowConfirm', false),
  withState('confirmCallback', 'setConfirmCallback', defaultConfirmCallback),
  withState('isLoading', 'setIsLoading', true),
  withProps(props => ({
    isDefaultCriteria: compare2Criteria({
      ...defaultCriteria,
      ...props.initCriteria,
    }, props.allCriteria[props.id]),
    removeCriteria: () => props.removeCriteriaById(props.id),
    setCriteria: data => props.updateCriteriaById({
      id: props.id,
      data,
    }),
    criteria: {
      ...defaultCriteria,
      ...props.initCriteria,
      ...props.allCriteria[props.id],
    },
    permissionToDelete: props.permissionToDelete,
  })),
  lifecycle({
    async componentDidMount() {
      if (!this.props.criteria || (this.props.saveFilter !== undefined && !this.props.saveFilter)) {
        let newCriteria = defaultCriteria;
        if (this.props.initCriteria) {
          newCriteria = {
            ...defaultCriteria,
            ...this.props.initCriteria,
          };
        }
        await this.props.setCriteria(newCriteria);
        await updateData(this.props, newCriteria);
      } else {
        await updateData(this.props, false);
      }
      await this.props.setIsLoading(false);
    },
    async componentWillUnmount() {
      if (this.props.timer) {
        clearTimeout(this.props.timer);
      }
    },
  }),
  withHandlers({
    goToPage,
    changePageSize,
    destroyItem,
    hideConfirm,
    sort,
    filter,
    resetFilter,
    updateData: props => async (criteria = false) => updateData(props, criteria),
  }),
  withPropsOnChange(['updateDataFlag'], (props) => {
    if (!props.isLoading) {
      console.log('withPropsOnChange([\'updateDataFlag\']');
      updateData(props);
    }
  }),
);

export default hoistStatics(enhance)(WithLoading(Grid));
