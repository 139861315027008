import { compose, hoistStatics, withState, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import Editor from './Editor';

const enhance = compose(
  translate('core'),
  withState('viewCode', 'setViewCode', false),
  withHandlers({
    toggleViewCode: props => async () => {
      props.setViewCode(!props.viewCode);
    },
    setButtonIcon: props => () => {
      return props.viewCode ? 'fa fa-html5' : 'fa fa-code';
    },
  }),
);

export default hoistStatics(enhance)(Editor);
