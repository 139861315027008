import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Post from './Post';
import fh from '../../../../helpers/form.helper';
import md from '../../../../helpers/metaData.helper';
import ModelService from '../../../../services/post.service';
import CardLoading from '../../../../components/CardLoading';
import WithLoading from '../../../../components/WithLoading';
import { uploadSelectors } from '../../../../reducers/upload';

const imageSelector = uploadSelectors.getTempFile('image');
const backgroundImageSelector = uploadSelectors.getTempFile('background_image');

const defaultFormData = {
  title: '',
  content: '',
  permalink: '',
  published_at: '',
  no_index: ModelService.INACTIVE,
  status: ModelService.ACTIVE,
  ml: {},
  ...md.metaFields,
};

const mapStateToProps = state => ({
  image: imageSelector(state),
  background_image: backgroundImageSelector(state),
});

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, image, background_image } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
    background_image: background_image && background_image.id ? background_image.id : '',
  });
  if (!errors) {
    if (redirect) return history.push(ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps),
  ...fh.defaultRecompose(defaultFormData),
  defaultProps({
    getImage: ModelService.getImage,
    preparePagesOptions: (pages, model) =>
      pages.filter(option => model && option.value !== model.id),
  }),
  lifecycle({
    async componentDidMount() {
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Post') + fh.getTitleEnd(props),
    imageSrc: props.image && props.image.url
      ? props.image.url : ModelService.getImage(props.formData.image, 150, 150),
    backgroundImageSrc: props.background_image && props.background_image.url
      ? props.background_image.url : ModelService.getImage(props.formData.background_image, 150, 150),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(Post, CardLoading)));
