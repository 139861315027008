import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  NavItem,
  Nav,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';

const MlFields = ({
  langs,
  fields,
  toggle,
  activeTab,
  defaultLanguage,
  formData,
  getLangFormData,
  onChange,
}) => (
  <React.Fragment>
    <div className="mb-2">
      <Nav tabs>
        {langs.map(lang => (
          <NavItem key={lang.name}>
            <NavLink
              className={classnames({ active: activeTab === lang.name })}
              onClick={() => { toggle(lang.name); }}
            >
              {lang.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {langs.map(lang => (
          <TabPane tabId={lang.name} key={lang.name} className="animated fadeIn">
            {fields({
              formData: getLangFormData(lang.name, formData),
              onChange: onChange(lang.name, defaultLanguage),
            })}
          </TabPane>
        ))}
      </TabContent>
    </div>
  </React.Fragment>
);

MlFields.propTypes = {
  langs: PropTypes.array.isRequired,
  fields: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  getLangFormData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MlFields;
