import React from 'react';
import PropTypes from 'prop-types';

const ColumnTitle = ({ column, value }) => (
  <React.Fragment>
    <td>
      {value}
    </td>
  </React.Fragment>
);

ColumnTitle.propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default ColumnTitle;
