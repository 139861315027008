import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import mlHelper from '../../helpers/ml.helper';
import Feedbacks from './Feedbacks';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/feedback.service';
import gc from '../../components/cms/gridComponents';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'name',
    title: t('Name'),
  },
  {
    name: 'email',
    title: t('E-mail'),
  },
  {
    name: 'phone',
    title: t('Phone'),
  },
  {
    name: 'company',
    title: t('Company'),
  },
  {
    name: 'industry',
    title: t('Industry'),
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    name: 'status',
    title: t('Status'),
    value: ({ item: { status } }) => (
      <Badge color={ModelService.getColorByStatus(status)}>
        {ModelService.getStatusTitle(status, t)}
      </Badge>
    ),
    filter: gc.selectFilter(ModelService.getStatusOptions(t)),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  mlHelper.core,
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Feedbacks);
