import { compose, hoistStatics, withState, defaultProps, lifecycle, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Settings from './Settings';
import fh from '../../helpers/form.helper';
import ModelService from '../../services/settings.service';
import CardLoading from '../../components/CardLoading';
import WithLoading from '../../components/WithLoading';

const settingsTypes = {
  GENERAL: 'general',
  SECURITY: 'security',
  INTEGRATIONS: 'integrations',
};

const init = async (props) => {
  if (props.match.url === '/settings') {
    return props.history.push('/settings/general');
  } else {
    const type = props.match.url.split('/').pop();
    if (Object.values(settingsTypes).indexOf(type) > -1) {
      props.setActiveTab(type);
    } else {
      return props.history.push('/');
    }
  }
  const { settings } = await ModelService.get();
  await props.setFormData(settings);
  await props.setIsLoading(false);
};

const onSubmit = props => redirect => async (event) => {
  const { formData, t } = props;
  await fh.beforeSave(event, props, redirect);
  const { settings, errors } = await ModelService.update(formData);
  if (!errors) {
    props.setFormData(settings);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  ...fh.defaultRecompose({
    admin: {},
    site: {},
    recaptcha: {},
  }),
  translate('core'),
  withState('activeTab', 'setActiveTab', settingsTypes.GENERAL),
  defaultProps({
    settingsTypes,
  }),
  lifecycle({
    async componentDidMount() {
      init(this.props);
    },
  }),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeByGroup: fh.onChangeFormDataElementByGroup,
    onChangeMl: fh.onChangeFormDataMlElement,
    goToType: props => type => props.history.push(ModelService.getUrlByType(type)),
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(Settings, CardLoading)));
