import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import Menu from './Menu';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/menu.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import canUpdate from '../../../components/cms/permissions/CanUpdate';

const defaultFormData = {
  title: '',
  type: ModelService.TYPE_URL,
  url: '',
  model_name: '',
  model_id: '',
  position: '',
  priority: 100,
  status: ModelService.ACTIVE,
  ml: {},
};

const applyModel = fh.createApplyModel(defaultFormData);

const afterSetModel = async (props, model) => {
  if (model.type === ModelService.TYPE_MODEL && model.model_name) {
    const modelItemOptions = props.modelItemList.length > 0
      ? props.modelItemList
      : await ModelService.getModelItemOptions(model.model_name);
    await props.setModelItemList(modelItemOptions);
  }
};

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, image } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
  });
  if (!errors) {
    if (redirect) return history.push(model.parent_id
      ? ModelService.getUpdateUrl(model.parent_id)
      : ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    await afterSetModel(props, model);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  ...fh.defaultRecompose(defaultFormData),
  withState('modelItemList', 'setModelItemList', []),
  defaultProps({
    getImage: ModelService.getImage,
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      await fh.initModelData(props, applyModel, ModelService, afterSetModel);
      if (props.match.params && props.match.params.parent_id) {
        props.setFormData({
          ...props.formData,
          parent_id: props.match.params.parent_id,
        });
      }
    },
  }),
  withProps(props => ({
    title: props.t('Menu') + fh.getTitleEnd(props),
    typeList: ModelService.getTypeOptions(props.t),
    modelList: ModelService.getModelOptions(props.t),
    positionList: ModelService.getPositionOptions(props.t),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
    onChangeModelName: props => async (event) => {
      event.persist();
      await props.setFormData({ ...props.formData, model_id: {}, model_name: event.target.value });
      await props.setModelItemList(await ModelService.getModelItemOptions(event.target.value));
    },
  }),
);

export default canUpdate(ModelService)(withRouter(hoistStatics(enhance)(WithLoading(Menu, CardLoading))));
