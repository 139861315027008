import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { Link } from 'react-router-dom';
import mlHelper from '../../../helpers/ml.helper';
import Posts from './Posts';
import columnTypes from '../../../components/cms/grid/columnTypes';
import ModelService from '../../../services/post.service';
import PreviewImage from '../../../components/previewImage';
import gc from '../../../components/cms/gridComponents';

const getColumns = (pageList, t) => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'title',
    title: t('Title'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.title}</Link>),
  },
  {
    name: 'image',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage height="35" src={ModelService.getImage(item.image, 100, 100)} alt={item.name} />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    name: 'published_at',
    title: t('Published at'),
    value: gc.dateDataCell({ attr: 'published_at' }),
    filter: false,
  },
  {
    name: 'status',
    title: t('Status'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'status' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  mlHelper.core,
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(Posts);
