import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import MlFields from '../../../components/cms/form/mlFields';
import InputFile from '../../../components/cms/form/inputFile';
import PreviewImage from '../../../components/previewImage';
import MetaDataFields from '../../../components/cms/metaDataFields';

const twoCols = { xs: 12, md: 4 };

const Page = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  onChangeMl,
  hasPermission,
  pageList,
  imageSrc,
}) => (
  <div>
    <Card>
      <CardHeader>
        <strong><i className="fa fa-edit pr-2" />{title}</strong>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit(true)}>
          <fieldset disabled={!hasPermission}>
            <Row>
              <Col {...twoCols}>
                <InputGroup
                  name="status"
                  label={t('Status')}
                  onChange={onChange}
                  errors={errors}
                  type="checkbox"
                  value={1}
                  checked={formData.status}
                />
              </Col>
              <Col {...twoCols}>
                <InputGroup
                  name="no_index"
                  label={t('No index')}
                  onChange={onChange}
                  errors={errors}
                  type="checkbox"
                  value={1}
                  checked={formData.no_index}
                />
              </Col>
            </Row>
            <InputGroup
              name="parent_id"
              type="select2"
              options={pageList}
              label={t('Parent')}
              onChange={onChange}
              errors={errors}
              value={formData.parent_id}
              isMulti={false}
              icon="fa fa-hashtag"
            />
            <MlFields
              onChange={onChangeMl}
              formData={formData}
              fields={props => (
                <div>
                  <InputGroup
                    name="permalink"
                    label={t('Permalink')}
                    errors={errors}
                    onChange={props.onChange}
                    formData={props.formData}
                    icon="fa fa-link"
                  />
                  <InputGroup
                    name="title"
                    label={t('Title')}
                    errors={errors}
                    onChange={props.onChange}
                    formData={props.formData}
                    icon="fa fa-pencil-square-o"
                  />
                  <InputGroup
                    name="content"
                    label={t('Content')}
                    type="editor"
                    errors={errors}
                    onChange={props.onChange}
                    formData={props.formData}
                  />
                  <MetaDataFields
                    onChange={props.onChange}
                    formData={props.formData}
                    errors={errors}
                  />
                </div>
              )}
            />
            <div>
              <InputFile id="image">
                <PreviewImage src={imageSrc} alt="img" height="120" />
              </InputFile>
            </div>
            <Row>
              <Col md={12}>
                <SubmitButtons
                  isSubmitting={isSubmitting}
                  onSubmit={onSubmit}
                  isSubmittingRedirect={isSubmittingRedirect}
                />
              </Col>
            </Row>
          </fieldset>
        </Form>
      </CardBody>
    </Card>
  </div>
);

Page.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  pageList: PropTypes.array.isRequired,
};

export default Page;
