import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Form } from 'reactstrap';
import Permissions from 'react-redux-permissions';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import InputFile from '../../../components/cms/form/inputFile';
import PreviewImage from '../../../components/previewImage';

const twoCols = { xs: 12, md: 6 };

const User = ({
  t,
  onChange,
  userId,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  image,
  getAvatar,
  hasPermission,
  languages,
  statusList,
  roles,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-id-card pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit(true)}>
              <fieldset disabled={!hasPermission && formData.id !== userId}>
                <Row>
                  <Col md={9}>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="username"
                          label={t('Username')}
                          name="username"
                          placeholder={t('Username')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.username}
                          icon="fa fa-user"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="email"
                          label={t('E-mail')}
                          name="email"
                          placeholder={t('E-mail')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.email}
                          icon="fa fa-at"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="first_name"
                          label={t('First name')}
                          name="first_name"
                          placeholder={t('First name')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.first_name}
                          icon="fa fa-address-card-o"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="last_name"
                          label={t('Last name')}
                          name="last_name"
                          placeholder={t('Last name')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.last_name}
                          icon="fa fa-address-card-o"
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row className="my-0">
                      <Col {...twoCols}>
                        <InputGroup
                          id="company_name"
                          label={t('Company name')}
                          name="company_name"
                          placeholder={t('Company name')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.company_name}
                          icon="fa fa-group"
                        />
                      </Col>
                      <Col {...twoCols}>
                        <InputGroup
                          id="phone"
                          label={t('Phone')}
                          name="phone"
                          placeholder={t('Phone')}
                          onChange={onChange}
                          errors={errors}
                          value={formData.phone}
                          icon="fa fa-phone"
                        />
                      </Col>
                    </FormGroup>
                    {formData.id === userId && (
                      <InputGroup
                        id="password"
                        label={t('Password')}
                        name="password"
                        placeholder="*******"
                        type="password"
                        autoComplete="new-password"
                        onChange={onChange}
                        errors={errors}
                        value={formData.password}
                        icon="fa fa-lock"
                      />
                    )}
                    {formData.id !== userId && (
                      <Permissions allowed={['update_users']}>
                        <InputGroup
                          id="password"
                          label={t('Password')}
                          name="password"
                          placeholder="*******"
                          type="password"
                          autoComplete="new-password"
                          onChange={onChange}
                          errors={errors}
                          value={formData.password}
                          icon="fa fa-lock"
                        />
                      </Permissions>
                    )}
                    <Permissions allowed={['update_roles']}>
                      <InputGroup
                        label={t('Roles')}
                        placeholder={t('Roles')}
                        name="rolesList"
                        type="select2"
                        onChange={onChange}
                        errors={errors}
                        value={formData.rolesList}
                        options={roles}
                        icon="fa fa-lock"
                      />
                    </Permissions>
                    <InputGroup
                      label={t('Language')}
                      name="admin_language"
                      type="select"
                      onChange={onChange}
                      errors={errors}
                      value={formData.admin_language}
                      options={languages}
                      empty="-"
                      icon="fa fa-language"
                    />
                    <Permissions allowed={['update_users']}>
                      <InputGroup
                        label={t('Status')}
                        name="status"
                        type="select"
                        onChange={onChange}
                        errors={errors}
                        value={formData.status}
                        options={statusList}
                        empty="-"
                        icon="fa fa-cog"
                      />
                    </Permissions>
                  </Col>
                  <Col md={3}>
                    <div className="center">
                      <InputFile id="image" >
                        <PreviewImage src={image && image.url ? image.url : getAvatar(formData.image, 150, 150, true)} height="120" />
                      </InputFile>
                    </div>
                    <InputGroup
                      name="enable_2fa"
                      label={t('Enable 2 factor authorization')}
                      onChange={onChange}
                      errors={errors}
                      type="checkbox"
                      value={1}
                      checked={formData.enable_2fa}
                    />
                    <Permissions allowed={['update_roles']}>
                      <InputGroup
                        name="is_super_admin"
                        label={t('Super admin')}
                        onChange={onChange}
                        errors={errors}
                        type="checkbox"
                        value={1}
                        checked={formData.is_super_admin}
                      />
                    </Permissions>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <SubmitButtons
                      isSubmitting={isSubmitting}
                      onSubmit={onSubmit}
                      isSubmittingRedirect={isSubmittingRedirect}
                    />
                  </Col>
                </Row>
              </fieldset>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

User.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  image: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusList: PropTypes.array.isRequired,
};

export default User;
