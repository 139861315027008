import { compose, hoistStatics, lifecycle, withState, defaultProps, withPropsOnChange } from 'recompose';
import { translate } from 'react-i18next';
import Pagination from './Pagination';
import gh from '../grid/grid.helper';

const calc = async ({ count, page, countButtons, pageSize, setCountPages, setPages, setShow }) => {
  await setShow(false);
  const countPages = gh.getPageCount(count, pageSize);
  const startPage = page - countButtons > -1 ? page - countButtons : 0;
  const endPage = page + countButtons + 1 > countPages ? countPages : page + countButtons + 1;
  const pagesLength = endPage - startPage;
  const pages = pagesLength
    ? Array.apply(null, new Array(pagesLength))
      .map(Number.prototype.valueOf, 0).map((el, i) => i + startPage)
    : [];
  await setCountPages(countPages);
  await setPages(pages);
  await setShow(pages.length > 1);
};

const enhance = compose(
  translate('core'),
  withState('show', 'setShow', false),
  withState('pages', 'setPages', []),
  withState('countPages', 'setCountPages', 0),
  withState('countButtons', 'setCountButtons', 5),
  lifecycle({
    async componentDidMount() {
      calc(this.props);
    },
  }),
  defaultProps({
    page: 0,
    pageSize: 10,
    count: 0,
  }),
  withPropsOnChange(['count', 'page', 'pageSize'], (props) => {
    calc(props);
  }),
);

export default hoistStatics(enhance)(Pagination);
