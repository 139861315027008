import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import Grid from '../../components/cms/grid';
import AddNewButton from '../../components/cms/grid/addNewButton';
import ModelService from '../../services/translation.service';

const initCriteria = {
  id: '',
  key: '',
  text: '',
  namespace: '',
  is_html: '',
};

const permissionToDelete = ['delete_translations'];
const permissionToCreate = ['create_translations'];

const Languages = ({ t, getColumns }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-align-justify" /> {t('Translations')}</strong>
          </CardHeader>
          <CardBody>
            <div className="pull-right mb-1">
              <AddNewButton
                to={ModelService.getCreateUrl()}
                permissionToCreate={permissionToCreate}
              />
            </div>
            <Grid
              id="translations"
              service={ModelService}
              columns={getColumns(t)}
              initCriteria={initCriteria}
              permissionToDelete={permissionToDelete}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Languages.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Languages;
