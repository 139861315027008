import Loadable from 'react-loadable';
import Logout from './views/logout';
import Users from './views/users';
import User from './views/users/user';
import Invite from './views/users/invite';
import Languages from './views/languages';
import Language from './views/languages/language';
import MetaDataList from './views/metaDataList';
import MetaData from './views/metaDataList/metaData';
import Pages from './views/pages';
import Page from './views/pages/page';
import Posts from './views/blog/posts';
import Post from './views/blog/posts/post';
import PostsCategories from './views/blog/postsCategories';
import PostCategory from './views/blog/postsCategories/postCategory';
import Feedbacks from './views/feedbacks';
import Feedback from './views/feedbacks/feedback';
import Vacancies from './views/content/vacancies';
import Vacancy from './views/content/vacancies/vacancy';
import Damages from './views/content/damages';
import Damage from './views/content/damages/damage';
import Solutions from './views/content/solutions';
import Solution from './views/content/solutions/solution';
import Brands from './views/content/brands';
import Brand from './views/content/brands/brand';
import Slides from './views/content/slides';
import Slide from './views/content/slides/slide';
import Team from './views/content/teamMembers';
import TeamMember from './views/content/teamMembers/teamMember';
import Menus from './views/menus';
import Menu from './views/menus/menu';
import Widgets from './views/widgets';
import Widget from './views/widgets/widget';
import JobApplications from './views/jobApplications';
import JobApplication from './views/jobApplications/jobApplication';
import Translations from './views/translates';
import Translation from './views/translates/translate';
import Settings from './views/settings';
import Loading from './components/Loading';

const Dashboard = Loadable({
  loader: () => import('./views/dashboard'),
  loading: Loading,
});

const Roles = Loadable({
  loader: () => import('./views/roles'),
  loading: Loading,
});

const Role = Loadable({
  loader: () => import('./views/roles/role'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const getRoutes = t => ([
  {
    path: '/',
    exact: true,
    name: t('Home'),
  },
  {
    path: '/users',
    exact: true,
    name: t('Users'),
  },
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    name: t('Dashboard'),
    permissions: [],
  },
  {
    path: '/pages',
    exact: true,
    component: Pages,
    name: t('Pages'),
    permissions: ['read_pages'],
  },
  {
    path: '/pages/create',
    exact: true,
    component: Page,
    name: t('Create'),
    permissions: ['create_pages'],
  },
  {
    path: '/pages/:id',
    exact: true,
    component: Page,
    name: t('Update'),
    permissions: ['read_pages'],
  },
  {
    path: '/posts',
    exact: true,
    component: Posts,
    name: t('Posts'),
    permissions: ['read_blog'],
  },
  {
    path: '/posts/create',
    exact: true,
    component: Post,
    name: t('Create'),
    permissions: ['create_blog'],
  },
  {
    path: '/posts/:id',
    exact: true,
    component: Post,
    name: t('Update'),
    permissions: ['update_blog'],
  },
  {
    path: '/posts-categories',
    exact: true,
    component: PostsCategories,
    name: t('PostsCategories'),
    permissions: ['read_blog'],
  },
  {
    path: '/posts-categories/create',
    exact: true,
    component: PostCategory,
    name: t('Create'),
    permissions: ['create_blog'],
  },
  {
    path: '/posts-categories/:id',
    exact: true,
    component: PostCategory,
    name: t('Update'),
    permissions: ['update_blog'],
  },
  {
    path: '/users/feedback',
    exact: true,
    component: Feedbacks,
    name: t('Feedback'),
    permissions: ['read_feedback'],
  },
  // {
  //   path: '/users/feedback/create',
  //   exact: true,
  //   component: Feedback,
  //   name: t('Create'),
  // },
  {
    path: '/users/feedback/:id',
    exact: true,
    component: Feedback,
    name: t('Update'),
    permissions: ['update_feedback'],
  },
  {
    path: '/content/vacancies',
    exact: true,
    component: Vacancies,
    name: t('Vacancies'),
    permissions: ['read_content'],
  },
  {
    path: '/content/vacancies/create',
    exact: true,
    component: Vacancy,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/vacancies/:id',
    exact: true,
    component: Vacancy,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/content/brands',
    exact: true,
    component: Brands,
    name: t('Brands'),
    permissions: ['read_content'],
  },
  {
    path: '/content/brands/create',
    exact: true,
    component: Brand,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/brands/:id',
    exact: true,
    component: Brand,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/content/slides',
    exact: true,
    component: Slides,
    name: t('Slides'),
    permissions: ['read_content'],
  },
  {
    path: '/content/slides/create',
    exact: true,
    component: Slide,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/slides/:id',
    exact: true,
    component: Slide,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/content/damages',
    exact: true,
    component: Damages,
    name: t('Damages'),
    permissions: ['read_content'],
  },
  {
    path: '/content/damages/create',
    exact: true,
    component: Damage,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/damages/:id',
    exact: true,
    component: Damage,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/content/solutions',
    exact: true,
    component: Solutions,
    name: t('Solutions'),
    permissions: ['read_content'],
  },
  {
    path: '/content/solutions/create',
    exact: true,
    component: Solution,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/solutions/:id',
    exact: true,
    component: Solution,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/content/team-members',
    exact: true,
    component: Team,
    name: t('Vacancies'),
    permissions: ['read_content'],
  },
  {
    path: '/content/team-members/create',
    exact: true,
    component: TeamMember,
    name: t('Create'),
    permissions: ['read_content'],
  },
  {
    path: '/content/team-members/:id',
    exact: true,
    component: TeamMember,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/menus',
    exact: true,
    component: Menus,
    name: t('Menus'),
    permissions: ['read_menus'],
  },
  {
    path: '/menus/create',
    exact: true,
    component: Menu,
    name: t('Create'),
    permissions: ['create_menus'],
  },
  {
    path: '/menus/:id',
    exact: true,
    component: Menu,
    name: t('Update'),
    permissions: ['read_menus'],
  },
  {
    path: '/menus/create/:parent_id',
    exact: true,
    component: Menu,
    name: t('Item'),
    permissions: ['update_menus'],
  },
  {
    path: '/translations',
    exact: true,
    component: Translations,
    name: t('Texts'),
    permissions: ['read_translations'],
  },
  {
    path: '/translations/create',
    exact: true,
    component: Translation,
    name: t('Create'),
    permissions: ['create_translations'],
  },
  {
    path: '/translations/:id',
    exact: true,
    component: Translation,
    name: t('Update'),
    permissions: ['update_translations'],
  },
  {
    path: '/content/widgets',
    exact: true,
    component: Widgets,
    name: t('Widgets'),
    permissions: ['read_content'],
  },
  {
    path: '/content/widgets/create',
    exact: true,
    component: Widget,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/content/widgets/create/:parent_id',
    exact: true,
    component: Widget,
    name: t('Item'),
    permissions: ['update_content'],
  },
  {
    path: '/content/widgets/:id',
    exact: true,
    component: Widget,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/job-applications',
    exact: true,
    component: JobApplications,
    name: t('Job Applications'),
    permissions: ['read_content'],
  },
  {
    path: '/job-applications/:id',
    exact: true,
    component: JobApplication,
    name: t('View'),
    permissions: ['read_content'],
  },
  {
    path: '/meta-data',
    exact: true,
    component: MetaDataList,
    name: t('Meta data'),
    permissions: ['read_content'],
  },
  {
    path: '/meta-data/create',
    exact: true,
    component: MetaData,
    name: t('Create'),
    permissions: ['create_content'],
  },
  {
    path: '/meta-data/:id',
    exact: true,
    component: MetaData,
    name: t('Update'),
    permissions: ['update_content'],
  },
  {
    path: '/settings',
    exact: true,
    component: Settings,
    name: t('Settings'),
    permissions: ['read_settings'],
  },
  {
    path: '/settings/general',
    exact: true,
    component: Settings,
    name: t('General'),
    permissions: ['read_settings'],
  },
  {
    path: '/settings/security',
    exact: true,
    component: Settings,
    name: t('Security'),
    permissions: ['read_settings'],
  },
  {
    path: '/settings/integrations',
    exact: true,
    component: Settings,
    name: t('Integrations'),
    permissions: ['read_settings'],
  },
  {
    path: '/languages',
    exact: true,
    component: Languages,
    name: t('Languages'),
    permissions: ['read_languages'],
  },
  {
    path: '/languages/create',
    exact: true,
    component: Language,
    name: t('Create'),
    permissions: ['create_languages'],
  },
  {
    path: '/languages/:id',
    exact: true,
    component: Language,
    name: t('Update'),
    permissions: ['update_languages'],
  },
  {
    path: '/users/index',
    exact: true,
    component: Users,
    // name: t('Users'),
    permissions: ['read_users'],
  },
  {
    path: '/users/roles',
    component: Roles,
    exact: true,
    name: t('Roles'),
    permissions: ['read_roles'],
  },
  {
    path: '/users/roles/create',
    component: Role,
    name: t('Create'),
    permissions: ['create_roles'],
  },
  {
    path: '/users/roles/:id',
    component: Role,
    name: t('Update'),
    permissions: ['read_roles'],
  },
  {
    path: '/profile',
    exact: true,
    component: User,
  },
  {
    path: '/users/create',
    exact: true,
    component: User,
    name: t('Create'),
    permissions: ['create_users'],
  },
  {
    path: '/users/create-invite',
    exact: true,
    component: Invite,
    name: t('Create invite'),
    permissions: ['create_users'],
  },
  {
    path: '/users/:id',
    exact: true,
    component: User,
    name: t('Update'),
    permissions: ['read_users'],
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    permissions: [],
  },
]);

export default getRoutes;
