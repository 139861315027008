import { createActions } from 'redux-actions';
import types from './types';

const {
  updateTempFile,
  removeTempFile,
  clearAllTempFiles,
  updateMulTempFile,
  removeMulTempFile,
  removeMulTempFiles,
} = createActions(
  types.UPDATE_TEMP_FILE,
  types.REMOVE_TEMP_FILE,
  types.CLEAR_ALL_TEMP_FILES,
  types.UPDATE_MUL_TEMP_FILE,
  types.REMOVE_MUL_TEMP_FILE,
  types.REMOVE_MUL_TEMP_FILES,
);

export default {
  updateTempFile,
  removeTempFile,
  clearAllTempFiles,
  updateMulTempFile,
  removeMulTempFile,
  removeMulTempFiles,
};
