import React from 'react';
import columnTypes from './columnTypes';
import Buttons from './buttons';

const getColumnTitle = (column) => {
  if (typeof column === 'string') return column;
  if (typeof column === 'object') {
    if (typeof column.title !== 'undefined') return column.title;
    if (column.name) return column.name;
  }
  return undefined;
};

const getColumnName = (column) => {
  if (typeof column === 'string') return column;
  if (typeof column === 'object' && column.name) return column.name;
  return '';
};

const getCellValue = (column, item, service, destroyItem, updateData) => {
  if (column.type === columnTypes.BUTTONS) {
    return (<Buttons
      onClickDelete={destroyItem}
      column={column}
      permissionToDelete={service.getDeletePermissions()}
      baseRoute={service.baseRoute}
      item={item}
    />);
  }
  const name = getColumnName(column);
  if (name) {
    if (typeof item[name] === 'undefined') return '';
    if (typeof column.value === 'function') return column.value({ item, updateData, name, service });
    return item[name];
  }
  return undefined;
};

const tdId = (item, column) => `td-${item.id}-${getColumnName(column)}${column.id}`;
const trId = item => `tr-${item.id}`;
const thId = column => `th-${getColumnName(column)}${column.id}`;
const fId = column => `f-${getColumnName(column)}${column.id}`;

const getPageCount = (count, pageSize) => Math.ceil(count / pageSize);

export default { getColumnTitle, getColumnName, getCellValue, tdId, trId, thId, getPageCount, fId };
