import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
// https://editor.method.ac - svg online redactor
import logo from '../../assets/img/brand/ll3.svg';
import sygnet from '../../assets/img/brand/lsm2.svg';

const bigLogoConfig = { src: logo, width: 150, alt: 'Econdri CMS' };
const smallLogoConfig = { src: sygnet, width: 40, alt: 'Econdri CMS' };

const Header = ({ onClickLogout, onClickProfile, t, getAvatar, user }) => (
  <React.Fragment>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand
      full={bigLogoConfig}
      minimized={smallLogoConfig}
    />
    <AppSidebarToggler className="d-md-down-none" display="lg" />
    <Nav className="ml-auto" navbar>
      <AppHeaderDropdown direction="down">
        <DropdownToggle nav>
          <img src={getAvatar(user.image, 35, 35, true)} alt="admin@bootstrapmaster.com" className="img-avatar" />
        </DropdownToggle>
        <DropdownMenu right style={{ right: 'auto' }}>
          <DropdownItem onClick={onClickProfile}><i className="fa fa-user" /> {t('Profile')}</DropdownItem>
          <DropdownItem onClick={onClickLogout}><i className="fa fa-lock" /> {t('Logout')}</DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    </Nav>
  </React.Fragment>
);

Header.propTypes = {
  onClickLogout: PropTypes.func.isRequired,
  onClickProfile: PropTypes.func.isRequired,
  getAvatar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default Header;
