import apiRoutes from './api.routes';
import ModelService from './model.service';

const TranslationService = new ModelService(apiRoutes.translations, '/translations', 'translations');

TranslationService.NAMESPACE_CORE = 'core';
TranslationService.NAMESPACE_FRONTEND = 'frontend';

TranslationService.IS_HTML_YES = 1;
TranslationService.IS_HTML_NO = 0;

TranslationService.getNamespaceList = t => ({
  [TranslationService.NAMESPACE_CORE]: t('Core'),
  [TranslationService.NAMESPACE_FRONTEND]: t('Frontend'),
});

TranslationService.getNamespaceOptions = t => TranslationService
  .prepareOptionsFromSimpleObject(TranslationService.getNamespaceList(t));

TranslationService.getNamespaceTitle = (status, t) => {
  const label = TranslationService.getNamespaceList(t)[status];
  return label || '-';
};

export default TranslationService;
