import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import Grid from '../../../components/cms/grid';
import AddNewButton from '../../../components/cms/grid/addNewButton';
import ModelService from '../../../services/postCategory.service';

const initCriteria = {
  id: '',
  title: '',
  image: '',
  status: '',
};

const PostsCategories = ({ t, getColumns, pageList }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-edit" /> {t('Posts categories')}</strong>
          </CardHeader>
          <CardBody>
            <div className="pull-right mb-1">
              <AddNewButton
                to={ModelService.getCreateUrl()}
                permissionToCreate={ModelService.getCreatePermissions()}
              />
            </div>
            <Grid id="posts-categories" service={ModelService} columns={getColumns(pageList, t)} initCriteria={initCriteria} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

PostsCategories.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default PostsCategories;
