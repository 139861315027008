import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import Confirm from './Confirm';

const enhance = compose(
  translate('core'),
  defaultProps({
    cancelCallback: () => {},
    successCallback: () => {},
  }),
);

export default hoistStatics(enhance)(Confirm);
