import { connect } from 'react-redux';
import { compose, hoistStatics, lifecycle, withState } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Layout from './Layout';
import AuthService from '../../services/auth.service';
import LanguageService from '../../services/language.service';
import { userActions } from '../../reducers/user';
import { authActions } from '../../reducers/auth';
import { settingsActions } from '../../reducers/settings';
import { permissionsActions } from '../../reducers/permissions';

const mapStateToProps = state => ({
  user: state.user.user,
  language: state.user.user ? state.user.user.admin_language : 'en',
  token: state.auth.token,
  langLength: state.settings.langs.length,
  permissions: state.permissions,
});

const mapDispatchToProps = {
  setUser: userActions.setUser,
  clearToken: authActions.clearToken,
  updateSettings: settingsActions.updateSettings,
  setPermissions: permissionsActions.setPermissions,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isLoading', 'setIsLoading', true),
  translate('core'),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      const { user } = props;
      if (!user) {
        try {
          await AuthService.setToken(props.token);
          const { user, permissions } = await AuthService.getCurrentUser();
          await props.setUser({ user });
          await props.setPermissions(permissions);
          await props.setIsLoading(false);
        } catch (e) {
          await props.clearToken();
        }
      } else {
        await props.setIsLoading(false);
      }
      if (props.langLength === 0) {
        props.updateSettings(await LanguageService.getSettings());
      }
      if (user) {
        if (user.admin_language && user.admin_language !== props.i18n.language) {
          props.i18n.changeLanguage(user.admin_language);
        }
      }
    },
  }),
);

export default withRouter(hoistStatics(enhance)(Layout));
