import { createActions } from 'redux-actions';
import types from './types';

const {
  updateCriteria,
  removeCriteria,
  clearAllCriteria,
} = createActions(
  types.UPDATE_CRITERIA,
  types.REMOVE_CRITERIA,
  types.CLEAR_ALL_CRITERIA,
);

export default {
  updateCriteria,
  removeCriteria,
  clearAllCriteria,
};
