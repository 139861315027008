import React from 'react';
import PropTypes from 'prop-types';
// import ReCAPTCHA from 'react-google-recaptcha';
import { Card, CardBody, CardHeader, Col, Row, FormGroup, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import InputFile from '../../../components/cms/form/inputFile';
import PreviewImage from '../../../components/previewImage';
// import ValidationMessage from '../../../components/cms/form/validation/ValidationMessage';

const twoCols = { xs: 12, md: 6 };

const Language = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  imageSrc,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-language pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit(true)}>
              <Row>
                <Col md={9}>
                  <FormGroup row className="my-0">
                    <Col {...twoCols}>
                      <InputGroup
                        name="name"
                        label={t('Name')}
                        onChange={onChange}
                        errors={errors}
                        value={formData.name}
                        icon="fa fa-flag"
                        disabled={!!formData.id}
                      />
                    </Col>
                    <Col {...twoCols}>
                      <InputGroup
                        name="title"
                        label={t('Title')}
                        onChange={onChange}
                        errors={errors}
                        value={formData.title}
                        icon="fa fa-flag-o"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-0">
                    <Col {...twoCols}>
                      <InputGroup
                        name="status"
                        label={t('Status')}
                        onChange={onChange}
                        errors={errors}
                        type="checkbox"
                        value={1}
                        checked={formData.status}
                      />
                    </Col>
                    <Col {...twoCols}>
                      <InputGroup
                        name="admin_status"
                        label={t('Admin GUI status')}
                        onChange={onChange}
                        errors={errors}
                        type="checkbox"
                        value={1}
                        checked={formData.admin_status}
                      />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="">
                    <InputFile id="image">
                      <PreviewImage src={imageSrc} className="" alt="img" height="120" />
                    </InputFile>
                  </div>
                  <InputGroup
                    name="is_default"
                    label={t('Is default')}
                    type="checkbox"
                    onChange={onChange}
                    errors={errors}
                    value={1}
                    disabled
                    checked={formData.is_default}
                  />
                  <InputGroup
                    name="priority"
                    label={t('Priority')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.priority}
                    icon="fa fa-sort-amount-asc"
                  />
                </Col>
              </Row>
              {/*<ReCAPTCHA*/}
                {/*sitekey="6LfZpncUAAAAAHTezoTvcizWtmNRrLxDoOOBQZcd"*/}
                {/*onChange={value => onChange({ target: { name: 'captcha', value } })}*/}
              {/*/>*/}
              {/*<ValidationMessage message={'Invalid captcha'} valid={!errors.captcha} />*/}
              <Row>
                <Col md={12}>
                  <SubmitButtons
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    isSubmittingRedirect={isSubmittingRedirect}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Language.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default Language;
