import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Page from './Page';
import fh from '../../../helpers/form.helper';
import md from '../../../helpers/metaData.helper';
import ModelService from '../../../services/page.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../reducers/upload';
import canUpdate from '../../../components/cms/permissions/CanUpdate';

const imageSelector = uploadSelectors.getTempFile('image');

const defaultFormData = {
  title: '',
  content: '',
  permalink: '',
  parent_id: '',
  no_index: ModelService.INACTIVE,
  status: ModelService.ACTIVE,
  ml: {},
  ...md.metaFields,
};

const mapStateToProps = state => ({
  image: imageSelector(state),
});

const mapDispatchToProps = {
  removeTempFile: uploadActions.removeTempFile,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, image } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
  });
  if (!errors) {
    if (redirect) return history.push(ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  ...fh.defaultRecompose(defaultFormData),
  withState('pageList', 'setPageList', []),
  defaultProps({
    getImage: ModelService.getImage,
    preparePagesOptions: (pages, model) =>
      pages.filter(option => model && option.value !== model.id),
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.setPageList(await ModelService.getAll());
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Page') + fh.getTitleEnd(props),
    imageSrc: props.image && props.image.url
      ? props.image.url : ModelService.getImage(props.formData.image, 150, 150),
    pageList: props.preparePagesOptions(props.pageList, props.formData),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  }),
);

export default canUpdate(ModelService)(withRouter(hoistStatics(enhance)(WithLoading(Page, CardLoading))));
