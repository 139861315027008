import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import MlFields from '../../../components/cms/form/mlFields';
import InputFile from '../../../components/cms/form/inputFile';
import PreviewFile from '../../../components/previewFile';
import PreviewImage from '../../../components/previewImage';
import ModelService from '../../../services/widget.service';
import ModelFileService from '../../../services/widgetFile.service';
import getDefaultColumns from '../getColumns';
import AddNewButton from '../../../components/cms/grid/addNewButton';
import Grid from '../../../components/cms/grid';
import Upload from '../../../components/cms/form/upload';
import columnTypes from '../../../components/cms/grid/columnTypes';
import InputColumn from '../../../components/cms/gridComponents/inputColumn';

const twoCols = { xs: 12, md: 6 };

const getColumns = (t) => {
  const defaultColumns = getDefaultColumns(t);
  return [
    ...defaultColumns.slice(0, 2),
    ...defaultColumns.slice(4),
  ];
};

const getFileColumns = t => [
  {
    id: 'file',
    name: 'name',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <PreviewFile height="35" src={ModelService.getImage(item.name)} alt={item.name} />
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'name_original',
    title: t('Name'),
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
    value: props => (<InputColumn {...props} />),
  },
  {
    name: 'status',
    title: t('Status'),
    value: props => (<InputColumn {...props} inputProps={{ type: 'checkbox' }} />),
  },
  {
    type: columnTypes.BUTTONS,
    showUpdateButton: false,
    customButtons: ({ item }) => (
      <a className="btn btn-primary btn-sm mr-1" href={ModelService.getImage(item.name)} download>
        <i className="fa fa-download" />
      </a>
    ),
  },
];

const initCriteria = {
  id: '',
  title: '',
  content: '',
  status: '',
  position: '',
  type: '',
  sortBy: 'priority',
  order: 'asc',
};

const Widget = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  videoUrl,
  videoDeUrl,
  videoNlUrl,
  isSubmitting,
  isSubmittingRedirect,
  onChangeMl,
  typeList,
  positionList,
  imageSrc,
  updateGridFiles,
}) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-star pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit(true)}>
              <Row>
                <Col xs="12" md="9">
                  <MlFields
                    onChange={onChangeMl}
                    formData={formData}
                    fields={props => (
                      <div>
                        <Row>
                          <Col {...twoCols}>
                            <InputGroup
                              name="title"
                              label={t('Title')}
                              errors={errors}
                              onChange={props.onChange}
                              formData={props.formData}
                              icon="fa fa-pencil-square-o"
                            />
                          </Col>
                          <Col {...twoCols}>
                            <InputGroup
                              name="subtitle"
                              label={t('Subtitle')}
                              errors={errors}
                              onChange={props.onChange}
                              formData={props.formData}
                              icon="fa fa-pencil-square-o"
                            />
                          </Col>
                        </Row>
                        <InputGroup
                          name="content"
                          label={t('Content')}
                          type="editor"
                          errors={errors}
                          onChange={props.onChange}
                          formData={props.formData}
                        />
                      </div>
                    )}
                  />
                  <div>
                    <Upload label={t('Upload your files')} id="images" />
                  </div>
                </Col>
                <Col xs="12" md="3">
                  <div>
                    <InputFile id="image" >
                      <PreviewImage src={imageSrc} alt="img" height="120" />
                    </InputFile>
                  </div>
                  <div>
                    <InputFile id="video" label={t('Video')} />
                    {videoUrl && (<ReactPlayer height="auto" width="100%" style={{ maxWidth: '640px' }} url={videoUrl} controls className="mb-2 mt-1" />)}
                  </div>
                  <div>
                    <InputFile id="video_de" label={t('Video') + ' DE'} />
                    {videoDeUrl && (<ReactPlayer height="auto" width="100%" style={{ maxWidth: '640px' }} url={videoDeUrl} controls className="mb-2 mt-1" />)}
                  </div>
                  <div>
                    <InputFile id="video_nl" label={t('Video') + ' NL'} />
                    {videoNlUrl && (<ReactPlayer height="auto" width="100%" style={{ maxWidth: '640px' }} url={videoNlUrl} controls className="mb-2 mt-1" />)}
                  </div>
                  <InputGroup
                    name="status"
                    label={t('Status')}
                    onChange={onChange}
                    errors={errors}
                    type="checkbox"
                    value={1}
                    checked={formData.status}
                  />
                  <InputGroup
                    name="priority"
                    label={t('Priority')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.priority}
                    icon="fa fa-sort-amount-asc"
                  />
                  {!formData.parent_id && (
                    <div>
                      <InputGroup
                        label={t('Position')}
                        name="position"
                        onChange={onChange}
                        errors={errors}
                        value={formData.position}
                        icon="fa fa-eye"
                      />
                      <InputGroup
                        label={t('Type')}
                        name="type"
                        type="select"
                        onChange={onChange}
                        errors={errors}
                        value={formData.type}
                        options={typeList}
                        icon="fa fa-cog"
                      />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <SubmitButtons
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    isSubmittingRedirect={isSubmittingRedirect}
                  />
                </Col>
              </Row>
            </Form>
            {formData.id && (
              <div>
                <Card className="mt-3">
                  <CardHeader>
                    <i className="fa fa-files-o" />
                    <strong>{t('Files')}</strong>
                  </CardHeader>
                  <CardBody>
                    <Grid
                      updateDataFlag={updateGridFiles}
                      id="widgetfiles"
                      service={ModelFileService}
                      columns={getFileColumns(t)}
                      initCriteria={{ widget_id: formData.id, sortBy: 'priority' }}
                      saveFilter={false}
                      showFilter={false}
                    />
                  </CardBody>
                </Card>
                {!formData.parent_id && (
                  <Card className="mt-3">
                    <CardHeader>
                      <i className="fa fa-star-half-full" />
                      <strong>{t('Subwidgets')}</strong>
                    </CardHeader>
                    <CardBody>
                      <div className="mb-2">
                        <AddNewButton to={ModelService.getItemCreateUrl(formData.id)} icon="fa fa-plus" text={t('Add item')} />
                      </div>
                      <Grid
                        id="subwidgets"
                        service={ModelService}
                        columns={getColumns(t)}
                        initCriteria={{ ...initCriteria, parent_id: formData.id }}
                        saveFilter={false}
                      />
                    </CardBody>
                  </Card>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Widget.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  typeList: PropTypes.array.isRequired,
  positionList: PropTypes.array.isRequired,
  updateGridFiles: PropTypes.bool.isRequired,
};

export default Widget;
