import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import Grid from '../../components/cms/grid';
import AddNewButton from '../../components/cms/grid/addNewButton';
import ModelService from '../../services/user.service';

const initCriteria = {
  order: 'desc',
  id: '',
  first_name: '',
  last_name: '',
  status: '',
  username: '',
  email: '',
  phone: '',
};

const permissionToDelete = ['delete_users'];
const permissionToCreate = ['create_users'];

const Users = ({ t, getColumns }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-users" /> {t('Users')}</strong>
          </CardHeader>
          <CardBody>
            <div className="pull-right mb-1">
              <Link className="btn btn-success btn-brand mr-1" to="/users/create-invite">
                <i className="fa fa-paper-plane mr-1" />
                {t('Send invite')}
              </Link>
              <AddNewButton
                to={ModelService.getCreateUrl()}
                icon="fa fa-user-plus"
                permissionToCreate={permissionToCreate}
              />
            </div>
            <Grid
              id="users"
              service={ModelService}
              columns={getColumns(t)}
              initCriteria={initCriteria}
              permissionToDelete={permissionToDelete}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Users.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default Users;
