import React from 'react';
import { Badge } from 'reactstrap';
import PreviewImage from '../previewImage';
import fh from '../../helpers/file.helper';

const PreviewFile = props => (
  <React.Fragment>
    {(props.src && fh.isImage(props.src)) && (
      <PreviewImage {...props} />
    )}
    {(props.src && !fh.isImage(props.src)) && (
      <Badge color="primary">{fh.getFileExt(props.src)}</Badge>
    )}
  </React.Fragment>
);

export default PreviewFile;
