import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import MlFields from '../../../components/cms/form/mlFields';

const twoCols = { xs: 12, md: 6 };

const Translation = ({ t, onChange, onSubmit, errors, formData, title, isSubmitting, isSubmittingRedirect, namespaceList, onChangeMl }) => (
  <div>
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-language pr-2" />{title}</strong>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit(true)}>
              <Row>
                <Col {...twoCols}>
                  <InputGroup
                    name="key"
                    label={t('Key')}
                    onChange={onChange}
                    errors={errors}
                    value={formData.key}
                    icon="fa fa-key"
                    disabled={!!formData.id}
                  />
                </Col>
                <Col {...twoCols}>
                  <InputGroup
                    name="namespace"
                    label={t('Namespace')}
                    onChange={onChange}
                    errors={errors}
                    type="select"
                    value={formData.namespace}
                    options={namespaceList}
                    empty={'-'}
                    icon="fa fa-space-shuttle"
                  />
                </Col>
              </Row>
              <InputGroup
                name="is_html"
                label={t('Is HTML')}
                onChange={onChange}
                errors={errors}
                type="checkbox"
                value={1}
                checked={formData.is_html}
              />
              <MlFields
                onChange={onChangeMl}
                formData={formData}
                fields={(props) => (
                  <div>
                    {!formData.is_html && (
                      <InputGroup
                        name="text"
                        label={t('Text')}
                        errors={errors}
                        onChange={props.onChange}
                        formData={props.formData}
                        icon="fa fa-file-text-o"
                      />
                    )}
                    {!!formData.is_html && (
                      <InputGroup
                        name="text"
                        type="editor"
                        label={t('Text')}
                        errors={errors}
                        onChange={props.onChange}
                        formData={props.formData}
                        icon="fa fa-flag-o"
                      />
                    )}
                  </div>
                )}
              />
              <Row>
                <Col md={12}>
                  <SubmitButtons
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    isSubmittingRedirect={isSubmittingRedirect}
                  />
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

Translation.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  namespaceList: PropTypes.array,
  onChangeMl: PropTypes.func.isRequired,
};

export default Translation;
