import { connect } from 'react-redux';
import { compose, hoistStatics, withState, withHandlers } from 'recompose';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Login from './Login';
import fh from '../../helpers/form.helper';
import AuthService from '../../services/auth.service';
import { userActions } from '../../reducers/user';
import { authActions } from '../../reducers/auth';
import { permissionsActions } from '../../reducers/permissions';

const mapDispatchToProps = {
  setUser: userActions.setUser,
  setToken: authActions.setToken,
  setPermissions: permissionsActions.setPermissions,
};

const onSubmit = props => async (event) => {
  event.preventDefault();
  const {
    token,
    user,
    permissions,
    banned,
    enable2fa,
  } = await AuthService.login(props.username, props.password);
  if ((token && user) || enable2fa) {
    await props.setWasValidated(true);
    await props.setValid(true);
    await props.setIsBanned(false);
    if (!(token && user)) {
      await props.setEnable2fa(enable2fa);
      toast.success(props.t('Check your e-mail inbox'));
    } else {
      await AuthService.setToken(token);
      await props.setUser({ user });
      await props.setToken({ token });
      await props.setPermissions(permissions);
    }
  } else {
    props.setValid(false);
    props.setWasValidated(true);
    if (banned) props.setIsBanned(true);
    else props.setIsBanned(false);
  }
};

const onSubmitCode = props => async (event) => {
  event.preventDefault();
  const {
    token,
    user,
    permissions,
  } = await AuthService.checkAuthCode(props.username, props.password, props.code);
  if ((token && user)) {
    await AuthService.setToken(token);
    await props.setUser({ user });
    await props.setToken({ token });
    await props.setPermissions(permissions);
  } else {
    toast.error(props.t('Invalid auth code'));
    props.setUsername('');
    props.setPassword('');
    props.setCode('');
    props.setWasValidated(false);
    props.setIsBanned(false);
    props.setEnable2fa(false);
  }
};

const enhance = compose(
  connect(null, mapDispatchToProps),
  translate('core'),
  withState('username', 'setUsername', ''),
  withState('password', 'setPassword', ''),
  withState('enable2fa', 'setEnable2fa', false),
  withState('code', 'setCode', ''),
  withState('valid', 'setValid', false),
  withState('isBanned', 'setIsBanned', false),
  withState('wasValidated', 'setWasValidated', false),
  withHandlers({
    onSubmit,
    onSubmitCode,
    onChange: fh.onChange,
  }),
);

export default withRouter(hoistStatics(enhance)(Login));
