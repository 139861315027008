import { compose, hoistStatics, withHandlers, lifecycle } from 'recompose';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import ConfirmInvite from './ConfirmInvite';
import fh from '../../helpers/form.helper';
import AuthService from '../../services/auth.service';
import WithLoading from '../../components/WithLoading';

const enhance = compose(
  ...fh.defaultRecompose({}),
  withHandlers({
    onSubmit: props => async (event) => {
      event.preventDefault();
      props.setIsSubmitting(true);
      const { errors } = await AuthService.confirmInvite(props.formData);
      props.setIsSubmitting(false);
      if (errors) {
        props.setErrors(errors);
      } else {
        props.setErrors({});
        toast.success(props.t('You are successfully registered. Please enter your credentials to log in.'));
        props.history.push('/login');
      }
    },
    onChange: fh.onChangeFormDataElement,
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      const { match: { params: { id, code } } } = props;
      const { status, user } = await AuthService.checkCode(id, code);
      if (status) {
        await props.setFormData({
          ...props.formData,
          id,
          code,
          ...user,
        });
        props.setIsLoading(false);
      } else {
        props.history.push('/login');
      }
    },
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(ConfirmInvite)));
