import apiRoutes from './api.routes';
import ModelService from './model.service';

const FeedbackService = new ModelService(apiRoutes.feedback, '/users/feedback', 'feedback');

FeedbackService.STATUS_NEW = 1;
FeedbackService.STATUS_IN_PROGRESS = 2;
FeedbackService.STATUS_DONE = 3;

FeedbackService.getStatusList = t => ({
  [FeedbackService.STATUS_NEW]: t('New'),
  [FeedbackService.STATUS_IN_PROGRESS]: t('In progress'),
  [FeedbackService.STATUS_DONE]: t('Done'),
});

FeedbackService.getStatusOptions = t => FeedbackService
  .prepareOptionsFromSimpleObject(FeedbackService.getStatusList(t));

FeedbackService.getStatusTitle = (status, t) => {
  const label = FeedbackService.getStatusList(t)[status];
  return label || '-';
};

FeedbackService.getColorByStatus = (status) => {
  switch (status) {
    case FeedbackService.STATUS_NEW:
      return 'success';
    case FeedbackService.STATUS_IN_PROGRESS:
      return 'warning';
    case FeedbackService.STATUS_DONE:
      return 'primary';
    default:
      return 'secondary';
  }
};

export default FeedbackService;
