import apiRoutes from './api.routes';
import ModelService from './model.service';

const MenuService = new ModelService(apiRoutes.menus, '/menus', 'menus');

/** Types */
MenuService.TYPE_URL = 1;
MenuService.TYPE_MODEL = 2;

MenuService.getTypeList = t => ({
  [MenuService.TYPE_URL]: t('Url'),
  [MenuService.TYPE_MODEL]: t('Model'),
});

MenuService.getTypeOptions = t => MenuService
  .prepareOptionsFromSimpleObject(MenuService.getTypeList(t));

MenuService.getTypeTitle = (status, t) => {
  const label = MenuService.getTypeList(t)[status];
  return label || '-';
};

/** Positions */
MenuService.POSITION_MAIN = 1;
MenuService.POSITION_FOOTER = 2;

MenuService.getPositionList = t => ({
  [MenuService.POSITION_MAIN]: t('Main menu'),
  [MenuService.POSITION_FOOTER]: t('Footer menu'),
});

MenuService.getPositionOptions = t => MenuService
  .prepareOptionsFromSimpleObject(MenuService.getPositionList(t));

MenuService.getPositionTitle = (position, t) => {
  const label = MenuService.getPositionList(t)[position];
  return label || '-';
};

export default MenuService;
