import { compose, hoistStatics, withProps } from 'recompose';
import { translate } from 'react-i18next';
import TempFile from './TempFile';

const enhance = compose(
  translate('core'),
  withProps(props => ({
    onRemove: typeof props.id === 'number' ? () => props.onRemove(props.id) : props.onRemove,
    hasError: props.tempFile && props.tempFile.message,
  })),
);

export default hoistStatics(enhance)(TempFile);
