import { handleActions } from 'redux-actions';
import types from './types';

const initialState = [];

const reducer = handleActions(
  {
    [types.SET_PERMISSIONS]: (state, action) => (
      [...state, ...action.payload]
    ),
    [types.UPDATE_PERMISSIONS]: (state, action) => (
      action.payload && (action.payload.length > state.length ?
        [...state, ...action.payload.filter(item => !state.includes(item))] :
        [...state.filter(item => action.payload.includes(item))])
    ),
    [types.CLEAR_PERMISSIONS]: () => (
      []
    ),
  },
  initialState,
);

export default reducer;
