import { compose, hoistStatics, defaultProps, withHandlers, lifecycle, withProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import InputFile from './InputFile';
import fileService from './../../../../services/file.service';
import { uploadActions } from '../../../../reducers/upload';

const getSetUploadingPcFunc = updateTempFile => uploadingPc => updateTempFile({
  uploadingPc,
});

const onChange = props => async (event) => {
  event.persist();
  const { target: { files: [file] } } = event;
  if (file) {
    const formData = new FormData();
    formData.append('file', file);
    await props.updateTempFile({
      original: file.name,
      size: file.size,
      uploadingPc: 0,
    });
    const data = await fileService.upload(
      formData,
      getSetUploadingPcFunc(props.updateTempFile),
    );
    await props.updateTempFile({
      id: data.id,
      filename: data.filename,
      url: data.message ? '' : fileService.getTempFilePath(data.filename),
      message: data.message,
      type: data.type,
    });
  }
};

const onRemove = props => () => {
  props.setShowConfirm(true);
};

const onRemoveCallback = props => async () => {
  props.setShowConfirm(false);
  props.removeTempFile();
  if (props.tempFile.id) {
    fileService.destroy(props.tempFile.id);
  }
};

const hideConfirm = ({ setShowConfirm }) => () => {
  setShowConfirm(false);
};

const mapStateToProps = state => ({
  tempFiles: state.upload.tempFiles,
});

const mapDispatchToProps = {
  updateTempFileById: uploadActions.updateTempFile,
  removeTempFileById: uploadActions.removeTempFile,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate('core'),
  withState('showConfirm', 'setShowConfirm', false),
  defaultProps({
    label: '',
  }),
  withProps(props => ({
    removeTempFile: () => props.removeTempFileById(props.id),
    updateTempFile: data => props.updateTempFileById({
      id: props.id,
      data,
    }),
    tempFile: props.tempFiles[props.id] || null,
  })),
  withHandlers({
    onChange,
    onRemove,
    hideConfirm,
    onRemoveCallback,
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.tempFile) this.props.removeTempFile(this.props.id);
    },
    componentWillUnmount() {
      if (this.props.tempFile) this.props.removeTempFile(this.props.id);
    },
  }),
);

export default hoistStatics(enhance)(InputFile);
