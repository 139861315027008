import { createSelector } from 'reselect';
import * as R from 'ramda';

const getTempFile = id => createSelector(
  R.pathOr(null, ['upload', 'tempFiles', id]),
  tempFile => tempFile,
);

const getMulTempFiles = id => createSelector(
  R.pathOr(null, ['upload', 'mulTempFiles', id]),
  tempFile => tempFile,
);

export default {
  getTempFile,
  getMulTempFiles,
};
