import { compose, hoistStatics, withHandlers, withState, withProps } from 'recompose';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import MlFields from './MlFields';

const mapStateToProps = state => ({
  langs: state.settings.langs,
  defaultLanguage: state.settings.defaultLanguage,
});

const enhance = compose(
  connect(mapStateToProps),
  translate('core'),
  withState('activeTab', 'setActiveTab', 'en'),
  withProps(props => ({
    isDefaultLang: lang => lang === props.defaultLanguage,
    getLangFormData: (lang, formData) => {
      if (lang === props.defaultLanguage) return formData;
      return formData && formData.ml && formData.ml[lang] ? formData.ml[lang] : {};
    },
  })),
  withHandlers({
    toggle: props => (tabId) => {
      props.setActiveTab(tabId);
    },
  }),
);

export default hoistStatics(enhance)(MlFields);
