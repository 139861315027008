import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';

import Grid from '../../components/cms/grid';
import ModelService from '../../services/jobApplications.service';

const initCriteria = {
  id: '',
  title: '',
  file: '',
  email: '',
  phone: '',
};

const JobApplications = ({ t, getColumns }) => (
  <div className="">
    <Row>
      <Col xl={12}>
        <Card>
          <CardHeader>
            <strong><i className="fa fa-envelope-o" /> {t('Job Applications')}</strong>
          </CardHeader>
          <CardBody>
            <Grid id="JobApplications" service={ModelService} columns={getColumns(t)} initCriteria={initCriteria} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

JobApplications.propTypes = {
  t: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
};

export default JobApplications;
