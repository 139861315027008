import { compose, hoistStatics, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import AddNewButton from './AddNewButton';

const enhance = compose(
  translate('core'),
  defaultProps({
    icon: 'fa fa-plus-square',
  }),
);

export default hoistStatics(enhance)(AddNewButton);
