import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form, Badge } from 'reactstrap';
import InputGroup from '../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../components/cms/form/submitButtons';
import dh from '../../../helpers/date.helper';
import InputFile from '../../../components/cms/form/inputFile';
import PreviewFile from '../../../components/previewFile';
import ModelService from '../../../services/jobApplications.service';
// import ModelFileService from '../../../services/jobApplicationsFile.service';
import InputColumn from '../../../components/cms/gridComponents/inputColumn';
import columnTypes from '../../../components/cms/grid/columnTypes';
// import Grid from '../../../components/cms/grid';

const twoCols = { xs: 12, md: 6 };

const getFileColumns = t => [
  {
    id: 'file',
    name: 'name',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <PreviewFile height="35" src={ModelService.getImage(item.name)} alt={item.name} />
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'name_original',
    title: t('Name'),
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
    value: props => (<InputColumn {...props} />),
  },
  {
    name: 'status',
    title: t('Status'),
    value: props => (<InputColumn {...props} inputProps={{ type: 'checkbox' }} />),
  },
  {
    type: columnTypes.BUTTONS,
    showUpdateButton: false,
    customButtons: ({ item }) => (
      <a className="btn btn-primary btn-sm mr-1" href={ModelService.getImage(item.name)} download>
        <i className="fa fa-download" />
      </a>
    ),
  },
];

const JobApplication = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  file,
  statusList,
  fileFullUrl,
  isSubmitting,
  isSubmittingRedirect,
}) => (
  <div>
    <Card>
      <CardHeader>
        <strong><i className="fa fa-envelope-o pr-2" />{title}</strong>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit(true)}>
          <Badge color="primary">{t('Created at')}: {dh.getFormattedDate(formData.created_at)}</Badge>
          <br />
          <Badge color="success">{t('Updated at')}: {dh.getFormattedDate(formData.updated_at)}</Badge>
          <br />
          <Badge className="mb-1" color="secondary">{t('Url')}: {formData.url}</Badge>
          <InputGroup
            name="title"
            label={t('Title')}
            onChange={onChange}
            errors={errors}
            value={formData.title}
            icon="fa fa-pencil"
          />
          <Row>
            <Col {...twoCols}>
              <InputGroup
                name="names"
                label={t('Names')}
                onChange={onChange}
                errors={errors}
                value={formData.names}
                icon="fa fa-user"
              />
            </Col>
            <Col {...twoCols}>
              <InputGroup
                name="email"
                label={t('Email')}
                onChange={onChange}
                errors={errors}
                value={formData.email}
                icon="fa fa-at"
              />
            </Col>
          </Row>
          <Row>
            <Col {...twoCols}>
              <InputGroup
                name="phone"
                label={t('Phone')}
                onChange={onChange}
                errors={errors}
                value={formData.phone}
                icon="fa fa-phone"
              />
            </Col>
            <Col {...twoCols}>
              <InputGroup
                name="external_link"
                label={t('External Link')}
                onChange={onChange}
                errors={errors}
                value={formData.external_link}
                icon="fa fa-link"
              />
            </Col>
          </Row>
          <InputGroup
            name="message"
            label={t('Message')}
            onChange={onChange}
            errors={errors}
            type="textarea"
            value={formData.message}
            icon="fa fa-envelope-open-o"
          />
          {/* <Row>
            <Col>
              <input
                name="accept_policy"
                type="checkbox"
                checked={formData.accept_policy}
                onChange={onChange} />
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
                <div style={{marginBottom: '10px'}}>
                  { formData.file !== '' &&
                    <a className="btn-brand btn btn-primary" href={fileFullUrl}>
                      <i className="fa fa-file-pdf-o  mr-2"/> Download CV
                    </a>
                  }
                  <InputFile id="file" >
                    <PreviewFile src={formData.file} alt="File" height="120" />
                  </InputFile>
                </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </div>
);

JobApplication.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default JobApplication;
