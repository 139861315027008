import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import InputGroup from '../../../../components/cms/form/inputGroup';
import SubmitButtons from '../../../../components/cms/form/submitButtons';
import MlFields from '../../../../components/cms/form/mlFields';
import InputFile from '../../../../components/cms/form/inputFile';
import PreviewImage from '../../../../components/previewImage';

const twoCols = { xs: 12, md: 4 };

const TeamMember = ({
  t,
  onChange,
  onSubmit,
  errors,
  formData,
  title,
  isSubmitting,
  isSubmittingRedirect,
  onChangeMl,
  imageSrc,
}) => (
  <div>
    <Card>
      <CardHeader>
        <strong><i className="fa fa-users pr-2" />{title}</strong>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit(true)}>
          <Row>
            <Col {...twoCols}>
              <MlFields
                onChange={onChangeMl}
                formData={formData}
                fields={props => (
                  <div>
                    <InputGroup
                      name="name"
                      label={t('Name')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-pencil-square-o"
                    />
                    <InputGroup
                      name="position"
                      label={t('Position')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa fa-certificate"
                    />
                    <InputGroup
                      name="description"
                      label={t('Description')}
                      errors={errors}
                      onChange={props.onChange}
                      formData={props.formData}
                      icon="fa fa-info"
                    />
                  </div>
                )}
              />
            </Col>
            <Col {...twoCols}>
              <InputGroup
                name="url"
                label={t('Url')}
                onChange={onChange}
                errors={errors}
                value={formData.url}
                icon="fa fa-link"
              />
              <InputGroup
                name="status"
                label={t('Status')}
                onChange={onChange}
                errors={errors}
                type="checkbox"
                value={1}
                checked={formData.status}
              />
              <InputGroup
                name="priority"
                label={t('Priority')}
                onChange={onChange}
                errors={errors}
                value={formData.priority}
                icon="fa fa-link"
              />
            </Col>
            <Col {...twoCols}>
              <div>
                <InputFile id="image">
                  <PreviewImage src={imageSrc} alt="img" height="120" />
                </InputFile>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <SubmitButtons
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                isSubmittingRedirect={isSubmittingRedirect}
              />
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  </div>
);

TeamMember.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isSubmittingRedirect: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default TeamMember;
