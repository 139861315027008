import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const Logout = ({ isLoading }) => (
  <div>
    {!isLoading && <Redirect to="/login" />}
  </div>
);

Logout.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default Logout;
