import React from 'react';
import PropTypes from 'prop-types';

const ColumnTitle = ({ title, column, canSort, icon, onSort }) => (
  <React.Fragment>
    <th scope="col">
      { canSort && (
        <a href="#" onClick={onSort}>{ title }<i className={icon} /></a>
      )}
      { !canSort && (
        <span>{ title }</span>
      )}
    </th>
  </React.Fragment>
);

ColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  canSort: PropTypes.bool.isRequired,
  column: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
};

export default ColumnTitle;
