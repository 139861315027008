import React from 'react';
import PropTypes from 'prop-types';
import { Table, Badge, Button } from 'reactstrap';
import gh from './grid.helper';
import Pagination from '../pagination';
import SelectLimit from './seletLimit';
import ColumnTitle from './columnTitle';
import Confirm from '../../confirm';
import ColumnFilter from './columnFIlter';
import DataCell from './dataCell';

const Grid = ({ data, showHeader, showFilter, columns, isDefaultCriteria, filter, service, count, resetFilter, updateData, goToPage, criteria, t, changePageSize, destroyItem, sort, hideConfirm, confirmCallback, showConfirm, permissionToDelete }) => (
  <div>
    <Table responsive hover striped>
      <thead>
        {!isDefaultCriteria && (
          <tr className="top-grid-tr">
            <td colSpan={columns.length}>
              <div className="pull-left mr-3">
                <Button className="btn-brand btn-sm" type="button" onClick={resetFilter}>
                  <i className="fa fa-power-off mr-1" />
                  {t('Reset filter')}
                </Button>
              </div>
            </td>
          </tr>
        )}
        {showHeader && (
          <tr>
            {columns.map(column => (
              <ColumnTitle key={gh.thId(column)} column={column} criteria={criteria} sort={sort} />
            ))}
          </tr>
        )}
        {showFilter && (
          <tr>
            {columns.map(column => (<ColumnFilter
              key={gh.fId(column)}
              column={column}
              criteria={criteria}
              filter={filter}
            />))}
          </tr>
        )}
      </thead>
      <tbody>
        {data.map(item =>(
          <tr key={gh.trId(item)}>
            {columns.map(column => (
              <DataCell
                key={gh.tdId(item, column)}
                service={service}
                column={column}
                item={item}
                destroyItem={destroyItem}
                updateData={updateData}
              />
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="small-tr">
          <td colSpan={columns.length - 1}>
            <div className="pull-left mr-3">
              <i className="fa fa-database mr-1" />
              <Badge> {t('Total')}: {count} {t('items')}</Badge>
            </div>
          </td>
          <td><div className="pull-right"><SelectLimit changePageSize={changePageSize} limit={criteria.limit} /></div></td>
        </tr>
        <tr>
          <td colSpan={columns.length} className="no-border">
            <div className="pull-right">
              <Pagination
                goToPage={goToPage}
                count={count}
                pageSize={criteria.limit}
                page={criteria.page}
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
    <Confirm show={showConfirm} cancelCallback={hideConfirm} successCallback={confirmCallback} />
  </div>
);

Grid.propTypes = {
  changePageSize: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ])),
  service: PropTypes.object.isRequired,
  criteria: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  destroyItem: PropTypes.func.isRequired,
  hideConfirm: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func,
  isDefaultCriteria: PropTypes.bool.isRequired,
  sort: PropTypes.func.isRequired,
  showConfirm: PropTypes.bool.isRequired,
  filter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
  showHeader: PropTypes.bool.isRequired,
  permissionToDelete: PropTypes.array,
};

Grid.defaultProps = {
  columns: [
    'id',
  ],
};

export default Grid;
