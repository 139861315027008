const getErrorMessage = (t, type, params) => {
  switch (type) {
    case 'required':
      return t('This field is required.');
    case 'unique':
    case 'unique2':
      return t('This field is unique.');
    case 'email':
      return t('E-mail is not valid.');
    case 'min':
      return `${t('The minimum field length is')} ${params}`;
    case 'max':
      return `${t('The maximum field length is')} ${params}`;
    case 'repeatPassword':
      return t('Please repeat password exactly.');
    case 'password':
      if (params === 'lowercase') return `${t('The password must contain at least 1 lowercase character.')}`;
      if (params === 'uppercase') return `${t('The password must contain at least 1 uppercase character.')}`;
      if (params === 'special') return `${t('The password must contain at least 1 special character')}: !?@#$%^&*()_+-=[]{};':"\\|,.<>\/`;
      if (params === 'number') return `${t('The password must contain at least 1 number.')}`;
    default:
      return '';
      t('Server is unavailable');
      t('Internal server error');
      t('Invalid file type');
  }
};

export default getErrorMessage;
