import apiRoutes from './api.routes';
import ModelService from './model.service';

const DamageService = new ModelService(apiRoutes.damages, '/content/damages', 'content');

/** Types */
DamageService.TYPE_FRONT = 1;
DamageService.TYPE_BACK = 2;
DamageService.TYPE_LEFT = 3;
DamageService.TYPE_RIGHT = 4;

DamageService.getTypeList = t => ({
  [DamageService.TYPE_FRONT]: t('Front'),
  [DamageService.TYPE_BACK]: t('Back'),
  [DamageService.TYPE_LEFT]: t('Left'),
  [DamageService.TYPE_RIGHT]: t('Right'),
});

DamageService.getTypeOptions = t => DamageService
  .prepareOptionsFromSimpleObject(DamageService.getTypeList(t));

DamageService.getTypeTitle = (status, t) => {
  const label = DamageService.getTypeList(t)[status];
  return label || '-';
};

DamageService.getImage = (filename, w = false, h = false, c = false) => DamageService.getFilePathByDir('/uploads/damages', filename, w, h, c);

export default DamageService;
