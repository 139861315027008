import React from 'react';
import { compose, hoistStatics, defaultProps, lifecycle, withState } from 'recompose';
import { Link } from 'react-router-dom';
import mlHelper from '../../helpers/ml.helper';
import Pages from './Pages';
import columnTypes from '../../components/cms/grid/columnTypes';
import ModelService from '../../services/page.service';
import PreviewImage from '../../components/previewImage';
import gc from '../../components/cms/gridComponents';

const getPageTitleById = (pageList, id) => {
  const pages = pageList.filter(item => parseInt(item.value, 10) === parseInt(id, 10));
  return pages[0] ? pages[0].label : '';
};

const getColumns = (pageList, t) => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'parent_id',
    title: t('Parent'),
    value: ({ item }) => (
      <React.Fragment>
        {item.parent_id && (<Link to={ModelService.getUpdateUrl(item.parent_id)}>{getPageTitleById(pageList, item.parent_id)} - #{item.parent_id}</Link>)}
        {!item.parent_id && (<span />) }
      </React.Fragment>
    ),
    filter: gc.select2Filter('parentFilter', false, pageList),
  },
  {
    name: 'title',
    title: t('Title'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.title}</Link>),
  },
  {
    name: 'image',
    title: '',
    value: ({ item }) => (
      <div className="center">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage height="35" src={ModelService.getImage(item.image, 100, 100)} alt={item.name} />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    name: 'updated_at',
    title: t('Updated at'),
    value: gc.dateDataCell({ attr: 'updated_at' }),
    filter: false,
  },
  {
    name: 'status',
    title: t('Status'),
    value: gc.checkBoxDataCell({ ModelService, t, attr: 'status' }),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  mlHelper.core,
  withState('pageList', 'setPageList', []),
  defaultProps({
    getColumns,
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.setPageList(await ModelService.getAll());
    },
  }),
);

export default hoistStatics(enhance)(Pages);
