import {compose, hoistStatics, defaultProps, withProps} from 'recompose';
import { translate } from 'react-i18next';
import Buttons from './Buttons';

const getUpdateUrl = (baseUrl, id) => `${baseUrl}/${id}`;

const enhance = compose(
  translate('core'),
  withProps(props => ({
    permissionToDelete: props.permissionToDelete,
    showUpdateButton: typeof props.column.showUpdateButton === 'undefined' ? true : !!props.column.showUpdateButton,
    showDeleteButton: typeof props.column.showDeleteButton === 'undefined' ? true : !!props.column.showDeleteButton,
    showViewButton: typeof props.column.showViewButton === 'undefined' ? false : !!props.column.showViewButton,
  })),
  defaultProps({
    getUpdateUrl,
    viewButtonUrl: '#',
    onClickDelete: props => id => console.log('deleted #', id),
  }),
);

export default hoistStatics(enhance)(Buttons);
