import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Damage from './Damage';
import fh from '../../../../helpers/form.helper';
import ModelService from '../../../../services/damage.service';
import CardLoading from '../../../../components/CardLoading';
import WithLoading from '../../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../../reducers/upload';

const imageSelector = uploadSelectors.getTempFile('image');

const defaultFormData = {
  type: '',
  title: '',
  position: '',
  description: '',
  status: ModelService.ACTIVE,
  priority: 100,
  ml: {},
};

const mapStateToProps = state => ({
  image: imageSelector(state),
});

const mapDispatchToProps = {
  removeTempFile: uploadActions.removeTempFile,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, image } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    image: image && image.id ? image.id : '',
  });
  if (!errors) {
    if (redirect) return history.push(ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    props.removeTempFile('image');
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  ...fh.defaultRecompose(defaultFormData),
  defaultProps({
    getImage: ModelService.getImage,
  }),
  lifecycle({
    async componentDidMount() {
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Damage') + fh.getTitleEnd(props),
    imageSrc: props.image && props.image.url
      ? props.image.url : ModelService.getImage(props.formData.image, 150, 150),
    typeList: ModelService.getTypeOptions(props.t),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(Damage, CardLoading)));
