import { combineReducers } from 'redux';
import user from './user';
import auth from './auth';
import permissions from './permissions';
import upload from './upload';
import settings from './settings';
import grid from './grid';

const appReducer = combineReducers({
  user,
  auth,
  upload,
  settings,
  grid,
  permissions,
});

export default (state, action) => appReducer(state, action);
