import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Invite from './Invite';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/user.service';
import LanguageService from '../../../services/language.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../reducers/upload';
import RoleService from '../../../services/role.service';
import { permissionsActions } from '../../../reducers/permissions';
import canUpdate from '../../../components/cms/permissions/CanUpdate';

const imageSelector = uploadSelectors.getTempFile('image');

const mapStateToProps = state => ({
  userId: state.user.user.id,
  image: imageSelector(state),
});

const mapDispatchToProps = {
  removeTempFile: uploadActions.removeTempFile,
  updatePermissions: permissionsActions.updatePermissions,
};

const defaultFormData = {
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  company_name: '',
  phone: '',
  password: '',
  image: '',
  admin_language: '',
  status: ModelService.STATUS_ACTIVE,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  event.preventDefault();
  props.setIsSubmitting(true);
  const { history, formData, t } = props;
  await fh.beforeSave(event, props, redirect);
  const { errors } = await ModelService.createInvite(formData);
  if (!errors) {
    fh.notify(t, t('The invite have been sent'));
    return history.push(ModelService.baseRoute);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    getAvatar: ModelService.getAvatar,
  }),
  withState('languages', 'setLanguages', []),
  withState('roles', 'setRoles', []),
  ...fh.defaultRecompose(defaultFormData),
  lifecycle({
    async componentDidMount() {
      const languages = await LanguageService.getAll({
        admin_status: 1,
      });
      const roles = await RoleService.getAll();

      await this.props.setLanguages(languages);
      await this.props.setRoles(roles);
      await fh.initModelData(this.props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Create invite'),
    statusList: ModelService.getStatusOptions(props.t),
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
  }),
);

export default canUpdate(ModelService)(withRouter(hoistStatics(enhance)(WithLoading(Invite, CardLoading))));
