import React from 'react';
import { compose, hoistStatics, defaultProps } from 'recompose';
import { Link } from 'react-router-dom';
import mlHelper from '../../../helpers/ml.helper';
import TeamMembers from './TeamMembers';
import columnTypes from '../../../components/cms/grid/columnTypes';
import ModelService from '../../../services/teamMember.service';
import PreviewImage from '../../../components/previewImage';
import gc from '../../../components/cms/gridComponents';
import InputColumn from '../../../components/cms/gridComponents/inputColumn';

const getColumns = t => [
  {
    name: 'id',
    title: t('Id'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.id}</Link>),
  },
  {
    name: 'name',
    title: t('Name'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.name}</Link>),
  },
  {
    name: 'position',
    title: t('Position'),
    value: ({ item }) => (<Link to={ModelService.getUpdateUrl(item.id)}>{item.position}</Link>),
  },
  {
    name: 'url',
    title: t('Position'),
    value: ({ item }) => (item.url ? (<a href={item.url} target="_blank">{item.url}</a>) : '-'),
  },
  {
    name: 'image',
    title: '',
    value: ({ item })=> (
      <div className="center">
        <Link to={ModelService.getUpdateUrl(item.id)}>
          <PreviewImage height="35" src={ModelService.getImage(item.image, 100, 100)} alt={item.name} />
        </Link>
      </div>
    ),
    filter: false,
    sort: false,
  },
  {
    name: 'created_at',
    title: t('Created at'),
    value: gc.dateDataCell({ attr: 'created_at' }),
    filter: false,
  },
  {
    name: 'priority',
    title: t('Priority'),
    filter: false,
    value: props => (<InputColumn {...props} />),
  },
  {
    name: 'status',
    title: t('Status'),
    value: props => (<InputColumn {...props} inputProps={{ type: 'checkbox' }} />),
    filter: gc.checkBoxFilter({ ModelService, t }),
  },
  {
    type: columnTypes.BUTTONS,
  },
];

const enhance = compose(
  mlHelper.core,
  defaultProps({
    getColumns,
  }),
);

export default hoistStatics(enhance)(TeamMembers);
