import { compose, hoistStatics, withProps, defaultProps } from 'recompose';
import { translate } from 'react-i18next';
import SubmitButtons from './SubmitButtons';

const loadingClassName = 'fa fa-spinner fa-spin mr-2';

const enhance = compose(
  translate('core'),
  defaultProps({
    isSubmittingRedirect: null,
    showSaveButton: true,
    showApplyButton: true,
    defaultApplyClassName: 'fa fa-save mr-2',
    defaultSaveClassName: 'fa fa-mail-reply mr-2',
  }),
  withProps(({
    isSubmittingRedirect,
    isSubmitting,
    defaultApplyClassName,
    defaultSaveClassName,
  }) => ({
    saveButtonClassName: isSubmitting && (isSubmittingRedirect || isSubmittingRedirect === null)
      ? loadingClassName : defaultSaveClassName,
    applyButtonClassName: isSubmitting && (!isSubmittingRedirect || isSubmittingRedirect === null)
      ? loadingClassName : defaultApplyClassName,
  })),
);

export default hoistStatics(enhance)(SubmitButtons);
