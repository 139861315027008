import { compose, hoistStatics, withHandlers, lifecycle, withProps, defaultProps, withState } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import JobApplication from './JobApplication';
import fh from '../../../helpers/form.helper';
import ModelService from '../../../services/jobApplications.service';
import CardLoading from '../../../components/CardLoading';
import WithLoading from '../../../components/WithLoading';
import { uploadSelectors, uploadActions } from '../../../reducers/upload';
import apiService from '../../../services/api.service';

const filesSelector = uploadSelectors.getTempFile('file');

const defaultFormData = {
  id: '',
  title: '',
  // file: '',
  email: '',
  phone: '',
  external_link: '',
  message: '',
  accept_policy: '',
};

const mapStateToProps = state => ({
  file: filesSelector(state),
});

const mapDispatchToProps = {
  removeTempFileById: uploadActions.removeTempFile,
};

const applyModel = fh.createApplyModel(defaultFormData);

const onSubmit = props => redirect => async (event) => {
  const { isNewModel, history, formData, t, file } = props;
  await fh.beforeSave(event, props, redirect);
  const { model, errors } = await ModelService.update({
    ...formData,
    file: file ? file.filename : '',
  });
  if (!errors) {
    if (redirect) return history.push(ModelService.baseRoute);
    if (isNewModel) return history.push(ModelService.getUpdateUrl(model.id));
    await applyModel(props.setFormData, model);
    props.removeTempFileById('file');
    fh.notify(t);
  }
  props.setErrors(!errors ? {} : errors);
  return props.setIsSubmitting(false);
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('updateGridFiles', 'setUpdateGridFiles', false),
  ...fh.defaultRecompose(defaultFormData),
  defaultProps({
    getFile: ModelService.getFile,
  }),
  lifecycle({
    async componentDidMount() {
      const { props } = this;
      await fh.initModelData(props, applyModel, ModelService);
    },
  }),
  withProps(props => ({
    title: props.t('Job Application') + fh.getTitleEnd(props),
    fileUrl: props.file && props.file.url
      ? props.file.url : props.getFile(props.formData.file),
    fileFullUrl: `${apiService.baseUrl}/uploads/temp-files/${props.formData.file}`,
  })),
  withHandlers({
    onSubmit,
    onChange: fh.onChangeFormDataElement,
    onChangeMl: fh.onChangeFormDataMlElement,
  }),
);

export default withRouter(hoistStatics(enhance)(WithLoading(JobApplication, CardLoading)));
