const getFileSize = (sizeinbytes) => {
  const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
  let fSize = sizeinbytes;
  let i = 0;
  while (fSize > 900) {
    fSize /= 1024;
    i += 1;
  }
  const size = Math.round(fSize * 100) / 100;
  return size + fSExt[i];
};

const getFileExt = fileName => fileName.split('.').pop();
const imageExts = ['jpg', 'jpeg', 'bmp', 'gif', 'png'];
const isImage = filename => imageExts.indexOf(getFileExt(filename).toLowerCase()) > -1;

export default {
  getFileSize,
  getFileExt,
  isImage,
};
